import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Container, Loader } from 'semantic-ui-react';
import InfiniteScroll from 'react-infinite-scroller';
import StoryItem from './StoryItem';
import { MetaData } from '../commons';

export const GET_STORIES = gql`
  query Stories($offset: Int, $limit: Int) {
    stories(offset: $offset, limit: $limit) {
      id
      title
      content
      cover_url
      created_at
    }
  }
`;

export default function Stories() {
  const [hasMore, setHasMore] = useState(true);
  const { loading, error, data, fetchMore } = useQuery(GET_STORIES, {
    variables: { offset: 0, limit: 20 },
  });
  if (loading && !data) return <Loader active inline="centered" className="mv-5" />;
  if (error) return null;
  if (!data || !data.stories) return null;
  const { stories } = data;
  if (stories && stories.length < 1) return null;

  return (
    <>
      <Container text className="mv-3">
        <InfiniteScroll
          loadMore={() => {
            setHasMore(false);
            if (!loading && hasMore) {
              fetchMore({
                variables: {
                  offset: stories.length,
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                  if (!fetchMoreResult) return previousResult;
                  return {
                    stories: [...previousResult.stories, ...fetchMoreResult.stories],
                  };
                },
              })
                .then(response => {
                  if (response.data.stories.length > 0) {
                    setHasMore(true);
                  }
                })
                .catch(err => {
                  if (err.graphQLErrors && err.graphQLErrors.length > 0) {
                    alert(err.graphQLErrors[0].message);
                  }
                });
            }
          }}
          pageStart={0}
          hasMore={hasMore}
          loader={<Loader active inline="centered" className="clear" key={0} />}
        >
          {stories.map(story => {
            return <StoryItem key={story.id} story={story} />;
          })}
        </InfiniteScroll>
      </Container>
      <MetaData title="체인지메이커스 스토리" />
    </>
  );
}
