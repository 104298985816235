import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Header } from 'semantic-ui-react';
import { Time } from '../commons';

function StoryItem({ story }) {
  const { id, title, created_at, cover_url } = story;
  return (
    <Link to={`/stories/${id}`}>
      <Card fluid className="mb-1">
        <div
          className="story-image"
          style={{
            backgroundImage: `url(${cover_url})`,
          }}
        />
      </Card>
      <div className="mb-3">
        <Header as="h2" content={title} className="mv-0" />
        <p className="mv-0 dimgray">
          <Time unix={created_at} format="LL" />
        </p>
        {/* <Truncate lines={2}>{content}</Truncate> */}
      </div>
    </Link>
  );
}

StoryItem.propTypes = {
  story: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string.isRequired,
    created_at: PropTypes.number.isRequired,
    cover_url: PropTypes.string,
  }).isRequired,
};

export default StoryItem;
