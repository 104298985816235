import React from 'react';
import { Button, Header } from 'semantic-ui-react';
import { Link, useLocation } from 'react-router-dom';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';

const USER_EVENTS_COUNT_QUERY = gql`
  query UserEventsCountQuery {
    userEventsCount
  }
`;

export default function ReservationTab() {
  const { data } = useQuery(USER_EVENTS_COUNT_QUERY);
  const { pathname } = useLocation();

  if (!data?.userEventsCount) return null;
  if (data.userEventsCount < 1) return <Header as="h2" content="대관 신청내역" />;
  return (
    <>
      <Button.Group className="mb-1" size="large">
        <Button
          as={Link}
          to="/reservations"
          active={pathname.includes(`/reservations`)}
          content="대관 신청"
        />

        <Button
          as={Link}
          to="/events"
          active={pathname.includes(`/events`)}
          content="소모임 신청"
        />
      </Button.Group>
    </>
  );
}
