import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Container, Divider, Header, Image, Loader } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { MetaData, Footer, SplitContent, Time } from '../commons';
import './stories.css';

export const GET_STORY = gql`
  query Story($id: ID!) {
    story(id: $id) {
      id
      title
      content
      cover_url
      created_at
    }
  }
`;

function Story({ match }) {
  const { id } = match.params;
  const { loading, error, data } = useQuery(GET_STORY, {
    variables: { id },
  });
  if (loading) return <Loader active inline="centered" className="mv-5" />;
  if (error) return null;
  if (!data || !data.story) return null;
  const { story } = data;
  const { title, content, cover_url, created_at } = story;
  return (
    <>
      <Container text className="mv-3">
        <Divider />
        <Header size="huge" textAlign="center" className="story-title">
          {title}
        </Header>
        <Divider />
        <div className="mt-4" />

        <Image src={cover_url} className="mv-2" />
        <SplitContent content={content} />
        <Divider hidden />
        <p className="small dimgray">
          <Time unix={created_at} format="LL" />
        </p>
      </Container>
      <Footer />
      <MetaData title="체인지메이커스 스토리" />
    </>
  );
}

Story.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }),
};

export default Story;
