import React, { Fragment } from 'react';
import { Button, Form, Icon, Label, Progress } from 'semantic-ui-react';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { DirectUploadProvider } from 'react-activestorage-provider';
import Globals from '../../constants/Globals';

const PURGE_RESERVATION_AGENDA = gql`
  mutation purgeReservationAgenda($id: ID!) {
    purgeReservationAgenda(id: $id) {
      id
      agenda_url
      agenda_filename
    }
  }
`;

function ReservationAgendaUploader({
  reservation,
  fileName,
  removeFileName,
  setFileName,
  setAgendaSignedId,
}) {
  const id = reservation && reservation.id;
  const [purgeReservationAgenda, { loading }] = useMutation(PURGE_RESERVATION_AGENDA);
  return (
    <Fragment>
      <p className="small mt-1">
        ※ 행사의 아젠다 및 일정/연사/참석자 등 자세한 내용을 알려 주세요. 승인 검토 및 프로모션을
        위한 참고 자료로만 활용됩니다.
      </p>
      <Form.Group grouped className="mt-2 mb-q">
        <label>아젠다 첨부</label>
        <DirectUploadProvider
          directUploadsPath={`${Globals.BASE_URL}/rails/active_storage/direct_uploads`}
          onSuccess={signedIds => setAgendaSignedId(signedIds)}
          render={({ handleUpload, uploads, ready }) => {
            return (
              <div>
                <Button primary as="label" htmlFor="file" className="mt-q" disabled={!!fileName}>
                  <Icon name="upload" />
                  파일 업로드
                </Button>
                {fileName ? (
                  <Label.Group>
                    <Label className="mt-h" size="small">
                      {fileName}
                      {id ? (
                        <Icon
                          name="delete"
                          loading={loading}
                          onClick={() => {
                            purgeReservationAgenda({
                              variables: { id },
                            });
                            removeFileName();
                          }}
                        />
                      ) : (
                        <Icon name="delete" onClick={() => removeFileName()} />
                      )}
                    </Label>
                  </Label.Group>
                ) : (
                  <p className="mt-h small gray">
                    ※ 아래 형식에 해당되는 파일을 첨부해주세요. <br />
                    (ppt, pptx, txt, doc, docx, xls, xlsx, pdf, hwp)
                  </p>
                )}
                <input
                  hidden
                  id="file"
                  type="file"
                  disabled={!ready}
                  accept=".ppt, .pptx, .txt, .doc, .docx, .xls, .xlsx, .pdf, .hwp"
                  onChange={e => {
                    handleUpload(e.currentTarget.files);
                    if (e.target.files.length > 0) {
                      setFileName(e);
                    }
                    e.target.value = null;
                  }}
                />

                {uploads.map(upload => {
                  switch (upload.state) {
                    case 'waiting':
                      return (
                        <p className="mt-h small" key={upload.id}>
                          업로드 대기중
                        </p>
                      );
                    case 'uploading':
                      return (
                        <Progress
                          label="업로드 중"
                          size="small"
                          color="blue"
                          key={upload.id}
                          percent={upload.progress}
                          progress
                        />
                      );
                    case 'error':
                      return (
                        <p className="mt-h small" key={upload.id}>
                          업로드 실패: {upload.error}
                        </p>
                      );
                    case 'finished':
                      return (
                        <p className="mt-h small" key={upload.id}>
                          {upload.file.name} 업로드 완료
                        </p>
                      );
                    default:
                      return null;
                  }
                })}
              </div>
            );
          }}
        />
      </Form.Group>
    </Fragment>
  );
}

export default ReservationAgendaUploader;
