import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'semantic-ui-react';

export default function Footer() {
  return (
    <div className="bg-whitesmoke pv-3 bv-1">
      <Container>
        <p className="small gray ta-c">
          <strong>주소</strong>
          {
            ' (04779) 서울시 성동구 뚝섬로1나길 5 헤이그라운드 성수시작점 지하 1층 체인지메이커스 | '
          }
          <strong>문의 </strong>
          <a href="mailto:info@changemakers.xyz" data-rel="external" className="gray">
            info@changemakers.xyz
          </a>
          {' | '}
          <Link to="/terms" className="fw-b gray">
            이용약관
          </Link>
          {' | '}
          <Link to="/privacy" className="fw-b gray">
            개인정보처리방침
          </Link>
        </p>
      </Container>
    </div>
  );
}
