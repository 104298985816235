import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';

const PoliciesTab = ({ url }) => {
  const buttons = [
    { id: 1, name: '이용약관', url: '/terms' },
    { id: 2, name: '개인정보처리방침', url: '/privacy' }
  ];

  return (
    <Button.Group>
      {buttons.map(button => (
        <Button
          active={button.url === url}
          as={Link}
          content={button.name}
          key={button.id}
          to={button.url}
        />
      ))}
    </Button.Group>
  );
};

export default PoliciesTab;
