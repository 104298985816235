import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import gql from 'graphql-tag';
import { Loader, Pagination } from 'semantic-ui-react';
import * as qs from 'query-string';

export const GET_USER_RESERVATIONS_COUNT = gql`
  query UserReservationsCount {
    userReservationsCount
  }
`;

export default function UserReservationsPagination() {
  const history = useHistory();
  const params = qs.parse(history.location.search);
  const page = params.page ? Number(params.page) : 1;

  const { loading, error, data } = useQuery(GET_USER_RESERVATIONS_COUNT);

  if (error) return null;
  if (loading) return <Loader active inline="centered" />;
  if (!data || !data.userReservationsCount || data.userReservationsCount < 20) return null;

  return (
    <Pagination
      className="mt-2"
      activePage={page}
      totalPages={data.userReservationsCount ? Math.ceil(data.userReservationsCount / 20) : 100}
      onPageChange={(e, { activePage }) => {
        history.push(`/reservations/?page=${activePage}`);
      }}
    />
  );
}
