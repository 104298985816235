import React from 'react';
import AutoLinkText from 'react-autolink-text2';

const SplitContent = ({ content }) => {
  if (!content) return null;
  return content.split('\n').map((line, i, arr) => {
    line = (
      <AutoLinkText
        key={i}
        text={line}
        linkProps={{ target: '_blank', className: 'fw-b' }}
      />
    );
    if (i === arr.length - 1) {
      return line;
    } else {
      return [line, <br key={i + 'br'} />];
    }
  });
};

export default SplitContent;
