import React from 'react';
import { Container, Header, List } from 'semantic-ui-react';
import { MetaData } from '../commons';
import { PoliciesTab } from '.';

const Terms = ({ match }) => {
  return (
    <>
      <div className="bg-white pv-3">
        <Container text textAlign="center">
          <PoliciesTab url={match.url} />
          <Header as="h2" content="개인정보처리방침" />
          <div className="ta-l">
            <Header as="h3" className="mb-q" content="서문" />
            <p>
              아쇼카는 개인정보 보호법 제30조, 정보통신망 이용촉진 및 정보보호 등에 관한 법률
              제27조의2에 따라 정보주체의 개인정보를 보호하고 이와 관련된 고충을 신속하고 원활하게
              처리할 수 있도록 다음과 같이 개인정보 처리방침을 수립, 공개합니다. 이용자가 기재한
              사항은 관련 법규에 근거하여 필요 시 공유되거나, 관련법령의 시행과 정책개발의 자료로
              사용될 수 있습니다.
            </p>
            <p>
              안전한 홈페이지 관리를 위해 보안에 노력을 하고 있으나, 만약의 침해 사고 시 문제가 될
              수 있는 민감한 정보의 기재는 피하여 주시기 바랍니다.
            </p>

            <Header as="h3" className="mb-q" content="개인정보의 처리목적" />
            <p>
              아쇼카는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의
              목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 개인정보 보호법
              제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
            </p>
            <List ordered>
              <List.Item>
                홈페이지 회원 가입 및 관리 : 회원 가입의사 확인, 회원제 서비스 제공에 따른 본인
                식별·인증, 회원자격 유지·관리, 제한적 본인확인제 시행에 따른 본인확인, 서비스
                부정이용 방지, 만 14세 미만 아동의 개인정보 처리시 법정대리인의 동의여부 확인, 각종
                고지 통지 및 공지사항 전달, 마케팅 및 광고 활용, 뉴스레터 발송, 서비스 만족도 조사
                등을 목적으로 개인정보를 처리합니다.
              </List.Item>

              <List.Item>
                재화 또는 서비스 제공 : 서비스 제공, 콘텐츠 제공, 맞춤서비스 제공, 공간 예약, 이벤트
                경품 배송, 문서 발송, 본인인증, 연령인증, 요금결제·정산, 채권추심 등을 목적으로
                개인정보를 처리합니다.
              </List.Item>

              <List.Item>
                고충처리, 분쟁해결 : 민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락·통지,
                분쟁조정을 위한 기록 보존, 처리결과 통보 등의 목적으로 개인정보를 처리합니다.
              </List.Item>

              <List.Item>
                개인정보 처리업무 : [개인정보 처리업무에 따른 처리목적]으로 개인정보를 처리합니다.
              </List.Item>
            </List>

            <Header as="h3" className="mb-q" content="개인정보의 처리 및 보유기간" />
            <p>
              아쇼카는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집시에
              동의 받은 개인정보 보유·이용기간 내에서 개인정보를 처리·보유합니다.
            </p>
            <p>각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.</p>
            <List ordered>
              <List.Item>
                홈페이지 회원 가입 및 관리 : 홈페이지 탈퇴시까지
                <br />
                (다만, 다음의 사유에 해당하는 경우에는 해당 사유 종료시까지)
                <List.List>
                  <List.Item>
                    관계 법령 위반에 따른 수사·조사 등이 진행중인 경우에는 해당 수사·조사 종료시까지
                  </List.Item>
                  <List.Item>
                    홈페이지 이용과 관련한 채권·채무관계가 잔존할 경우에는 해당 채권·채무관계
                    정산시까지
                  </List.Item>
                </List.List>
              </List.Item>

              <List.Item>
                재화 또는 서비스 제공 : 재화·서비스 공급완료 및 요금결제·정산 완료시까지
              </List.Item>

              <List.Item>[개인정보 처리업무] : 탈퇴시</List.Item>
            </List>

            <p>
              법령의 규정에 의하여 개인정보를 보존할 의무가 있는 경우 아쇼카는 해당 법령에 명시된
              기간 동안 이용자의 개인정보를 보존합니다. 이 때 이용자의 개인정보는 보관의 목적으로만
              이용하며 보존기간은 다음과 같습니다.
            </p>

            <List ordered>
              <List.Item>
                아쇼카 내부 방침에 의한 정보보유 사유
                <List.List>
                  <List.Item>보존이유: 부정이용 방지</List.Item>
                  <List.Item>보존기간: 3년</List.Item>
                </List.List>
              </List.Item>

              <List.Item>
                모든 거래에 관한 장부, 전표 및 증빙서류와 관련된 정보
                <List.List>
                  <List.Item>보존이유: 국세기본법</List.Item>
                  <List.Item>보존기간: 5년</List.Item>
                </List.List>
              </List.Item>

              <List.Item>
                계약 또는 청약철회 등에 관한 기록
                <List.List>
                  <List.Item>보존이유: 전자상거래 등에서의 소비자보호에 관한 법률</List.Item>
                  <List.Item>보존기간: 5년</List.Item>
                </List.List>
              </List.Item>

              <List.Item>
                대금결제 및 재화 등의 공급에 관한 기록
                <List.List>
                  <List.Item>보존이유: 전자상거래 등에서의 소비자보호에 관한 법률</List.Item>
                  <List.Item>보존기간: 5년</List.Item>
                </List.List>
              </List.Item>

              <List.Item>
                이용자의 불만 또는 분쟁처리에 관한 기록
                <List.List>
                  <List.Item>전자상거래 등에서의 소비자보호에 관한 법률</List.Item>
                  <List.Item>보존기간: 3년</List.Item>
                </List.List>
              </List.Item>

              <List.Item>
                웹사이트 방문기록(방문사실에 관한 로그기록자료, 위치를 확인할 수 있는 접속지
                추적자료)
                <List.List>
                  <List.Item>보존이유: 통신비밀보호법</List.Item>
                  <List.Item>보존기간: 3개월</List.Item>
                </List.List>
              </List.Item>
            </List>

            <Header as="h2" content="14세 미만 아동을 포함한 이용자의 권리·의무 및 행사방법" />
            <List ordered>
              <List.Item>
                이용자는 아쇼카에 대해 언제든지 다음의 개인정보 보호 관련 권리를 행사할 수 있습니다.
                <List.List>
                  <List.Item>개인정보 열람 요구</List.Item>
                  <List.Item>오류 등이 있을 경우 정정 요구</List.Item>
                  <List.Item>삭제 요구</List.Item>
                  <List.Item>처리정지 요구</List.Item>
                </List.List>
              </List.Item>
              <List.Item>
                이용자는 언제든지 등록되어 있는 자신의 개인정보를 조회·수정할 수 있으며, 아쇼카의
                개인정보 처리에 동의하지 않는 경우 동의를 거부하거나 회원탈퇴를 요청할 수 있습니다.
                다만, 이 경우 서비스의 전체 또는 일부 이용이 어려울 수 있습니다.
              </List.Item>

              <List.Item>
                이용자는 개인정보 조회, 수정을 위해서 홈페이지에서 직접 열람, 정정 또는 탈퇴가
                가능합니다.
              </List.Item>
              <List.Item>
                이용자가 개인정보의 오류 등에 대한 정정 또는 삭제를 요청한 경우에는 정정 또는 삭제를
                완료하기 전까지 당해 개인정보를 이용하거나 제공하지 않습니다.
              </List.Item>

              <List.Item>
                권리 행사는 이용자의 법정대리인이나 위임을 받은 자 등 대리인을 통해 할 수 있습니다.
              </List.Item>

              <List.Item>
                만 14세 아동의 경우 법정대리인이 아동의 개인정보를 조회하거나 수정할 권리, 수집 및
                이용 동의를 철회할 권리를 갖습니다.
              </List.Item>
            </List>

            <Header as="h3" className="mb-q" content="처리하는 개인정보 항목" />
            <List ordered>
              <List.Item>
                아쇼카는 회원가입, 원활한 상담, 각종 서비스의 제공을 위해 최초 회원가입이나 서비스
                이용 신청 당시 아래와 같은 최소한의 개인정보를 필수항목으로 수집하고 있습니다.
                <List.List>
                  <List.Item>
                    [회원가입] 이름, 성, 생년월일, 이메일, 전화번호, 비밀번호, 기관명, 소개,
                    소속분야
                  </List.Item>
                  <List.Item>
                    [대관 신청] 이름, 주최 기관명, 이메일, 연락처 모임명, 참석자수, 대관목적,
                    공개여부, 모임 유/무료 여부, 필요장비, 캐이터링 여부, 현수막/포스터 부착여부,
                    모임상세 정보, 아젠다, 홍보유무, 홍보포스터 파일, 행사 정보 링크
                  </List.Item>
                </List.List>
              </List.Item>

              <List.Item>
                아쇼카는 서비스 이용과정이나 사업처리 과정에서 아래와 같은 정보들이 자동으로
                생성되어 수집될 수 있습니다.
                <List.List>
                  <List.Item>
                    IP 주소, MAC 주소, 브라우저 종류, 쿠키, 방문 일시, 방문 경로, 서비스 이용 기록,
                    불량이용 기록
                  </List.Item>
                </List.List>
              </List.Item>

              <List.Item>
                아쇼카는 이용자가 우편, 전화, 이메일 또는 온라인 전자서식 등 전자적 방법을 통한
                의사표시를 할 때 관련 정보를 수집할 수 있습니다.
              </List.Item>
            </List>
            <p>
              ※ 위 개인정보는 수집 당시 개인정보의 내용은 물론 사후 변경된 개인정보의 내용을
              보유하는 경우도 포함합니다.
            </p>

            <Header as="h3" className="mb-q" content="개인정보의 파기절차 및 방법" />
            <p>
              아쇼카는 개인정보 보유기간이 경과하거나 처리목적이 달성되는 등 개인정보가 불필요하게
              되었을 때에는 지체없이 해당 개인정보를 파기합니다. 단, 이용자로부터 동의 받은 개인정보
              보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를
              계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)에 옮기거나
              보관장소를 달리하여 보존합니다.
            </p>
            <p>파기절차 및 방법은 다음과 같습니다.</p>
            <List ordered>
              <List.Item>
                파기절차 : 아쇼카는 파기 사유가 발생한 개인정보를 선정하고, 아쇼카의 개인정보
                보호책임자의 승인을 받아 개인정보를 파기합니다. 이용자가 회원가입이나 대관신청 등을
                위해 입력한 정보는 목적이 달성된 후 별도의 DB로 옮겨져 내부 방침 및 기타 관련 법령에
                의한 정보보호 사유에 따라 일정 기간 저장된 후 파기됩니다. 별도 DB로 옮겨진
                개인정보는 법률에 의한 경우가 아니고서는 보유 이외의 다른 목적으로 이용되지
                않습니다.
              </List.Item>

              <List.Item>
                파기방법: 아쇼카는 전자적 파일형태로 기록·저장된 개인정보는 기록을 재생할 수 없도록
                파기합니다. 종이문서에 기록·저장된 개인정보는 분쇄기로 분쇄하거나 소각하여
                파기합니다.
              </List.Item>
            </List>

            <Header as="h3" className="mb-q" content="개인정보의 안정성 확보조치에 관한 사항" />
            <p>아쇼카 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.</p>
            <List ordered>
              <List.Item>관리적 조치 : 내부관리계획 수립·시행, 정기적 직원 교육 등</List.Item>
              <List.Item>
                기술적 조치 : 개인정보처리시스템 등의 접근권한 관리, 접근통제시스템 설치,
                고유식별정보 등의 암호화, 보안프로그램 설치
              </List.Item>
              <List.Item>물리적 조치 : 전산실, 자료보관실 등의 접근통제</List.Item>
            </List>

            <Header as="h3" className="mb-q" content="개인정보 보호책임자에 관한 사항" />
            <p>
              아쇼카는 개인정보처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한
              정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고
              있습니다.
            </p>
            <List ordered>
              <List.Item className="mb-1">
                개인정보 보호책임자
                <br />
                성명 : 이혜영
                <br />
                직책 : 대표
                <br />
                연락처 : 02-737-6978,
                <br />
                ※ 개인정보 보호 담당부서로 연결됩니다.
                <br />
              </List.Item>

              <List.Item>
                개인정보 보호 담당부서
                <br />
                부서명 : 커뮤니케이션 팀
                <br />
                담당자 : 정지선
                <br />
                {'연락처 : 02-737-6978 '}
                <a href="mailto:korea@ashoka.org" data-rel="external" className="fw-b">
                  korea@ashoka.org
                </a>
              </List.Item>
            </List>

            <p>
              정보주체께서는 아쇼카의 서비스를 이용하시면서 발생한 모든 개인정보 보호 관련 문의,
              불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수
              있습니다. 아쇼카는 정보주체의 문의에 대해 지체없이 답변 및 처리해드릴 것입니다.
            </p>

            <Header as="h3" className="mb-q" content="개인정보 처리방침 변경에 관한 사항" />
            <p>이 개인정보 처리방침은 2017. 12. 1부터 적용됩니다.</p>
          </div>
        </Container>
      </div>
      <MetaData title="체인지메이커스 개인정보처리방침" />
    </>
  );
};

export default Terms;
