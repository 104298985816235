import gql from 'graphql-tag';

const RESERVATION_FRAGMENT = gql`
  fragment ReservationFragment on Reservation {
    id
    status
    status_ko
    status_color
    start_datetime
    end_datetime
    space_ids
    spaces {
      id
      name
    }
    category
    category_ko
    category_color
    name
    institution
    email
    phone
    title
    number_of_participants
    purpose
    purpose_ko
    is_public
    cost
    microphone
    projector
    sound_equipment
    equipment_request
    catering
    banner
    description
    promotion
    website
    is_manageable
    agenda_url
    agenda_filename
    agreed_to_terms
  }
`;

export default RESERVATION_FRAGMENT;
