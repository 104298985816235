import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
// import { UserEvents, NewEvent, EditEvent, Event } from './components/events';
import {
  Spaces,
  Space1,
  Space2,
  // ReservationStep1,
  // ReservationStep2,
  // Covid19Guide,
} from './components/spaces';
// import { EditReservation, Reservation, UserReservations } from './components/reservations';
import { Story, Stories } from './components/stories';
// import { Login, Signup, ResetPassword, EditPassword } from './components/auth';
import { Terms, Privacy, LibraryPrivacy, LibraryTerms } from './components/policies';
import Navbar from './components/Navbar';
import { Announcement } from './components/commons';
import Home from './components/Home';
import Programs from './components/Programs';
import ScrollToTop from './components/ScrollToTop';
import { StatusContext } from './StatusContext';
import './App.css';

class App extends Component {
  // constructor(props) {
  //   super(props);

  //   const token = localStorage.getItem('token');

  //   this.logout = () => {
  //     this.setState({ authenticated: false });
  //   };

  //   this.login = () => {
  //     this.setState({ authenticated: true });
  //   };

  //   this.state = {
  //     authenticated: !!token,
  //     logout: this.logout,
  //     login: this.login,
  //   };
  // }

  render() {
    return (
      <StatusContext.Provider value={this.state}>
        <Router>
          <ScrollToTop>
            <>
              <Navbar />
              <Announcement />
              <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/spaces" component={Spaces} />
                <Route exact path="/spaces/1" component={Space1} />
                <Route exact path="/spaces/2" component={Space2} />
                <Route exact path="/stories" component={Stories} />
                <Route exact path="/stories/:id" component={Story} />
                {/* <PrivateRoute exact path="/reservations/covid19_guide" component={Covid19Guide} />
                <PrivateRoute exact path="/reservations/step1" component={ReservationStep1} />
                <PrivateRoute exact path="/reservations/step2" component={ReservationStep2} />
                <PrivateRoute exact path="/reservations" component={UserReservations} />
                <PrivateRoute exact path="/reservations/:id" component={Reservation} />
                <PrivateRoute exact path="/reservations/:id/edit" component={EditReservation} />
                <PrivateRoute exact path="/events/new" component={NewEvent} />
                <PrivateRoute exact path="/events" component={UserEvents} />
                <PrivateRoute exact path="/events/:id" component={Event} />
                <PrivateRoute exact path="/events/:id/edit" component={EditEvent} /> */}
                <Route exact path="/programs" component={Programs} />
                <Route exact path="/terms" component={Terms} />
                <Route exact path="/privacy" component={Privacy} />
                <Redirect from="/changemaker_news_privacy" to="/changemaker_library_privacy" />
                <Redirect from="/changemaker_news_terms" to="/changemaker_library_terms" />
                <Route exact path="/changemaker_library_privacy" component={LibraryPrivacy} />
                <Route exact path="/changemaker_library_terms" component={LibraryTerms} />
                {/* <VisitorOnlyRoute exact path="/login" component={Login} />
                <VisitorOnlyRoute exact path="/signup" component={Signup} />
                <VisitorOnlyRoute exact path="/reset_password" component={ResetPassword} />
                <VisitorOnlyRoute exact path="/edit_password/:token" component={EditPassword} /> */}
              </Switch>
            </>
          </ScrollToTop>
        </Router>
      </StatusContext.Provider>
    );
  }
}

// const VisitorOnlyRoute = ({ component: Component, location, ...rest }) => (
//   <StatusContext.Consumer>
//     {({ authenticated }) => {
//       const { from } = location.state || { from: { pathname: '/' } };
//       if (authenticated) return <Redirect to={from} />;
//       return <Route {...rest} render={props => <Component {...props} />} />;
//     }}
//   </StatusContext.Consumer>
// );

// const PrivateRoute = ({ component: Component, location, ...rest }) => (
//   <StatusContext.Consumer>
//     {({ authenticated }) => {
//       if (!authenticated)
//         return (
//           <Redirect
//             to={{
//               pathname: '/login',
//               state: { from: location },
//             }}
//           />
//         );
//       return <Route {...rest} render={props => <Component {...props} />} />;
//     }}
//   </StatusContext.Consumer>
// );

export default App;
