import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import * as qs from 'query-string';
import { Link, useHistory } from 'react-router-dom';
import { Button, Container, Grid } from 'semantic-ui-react';

import { ReservationItem, UserReservationsPagination, ReservationTab } from '.';

export const GET_USER_RESERVATIONS = gql`
  query UserReservations($offset: Int, $limit: Int) {
    userReservations(offset: $offset, limit: $limit) {
      id
      user_id
      start_datetime
      end_datetime
      spaces {
        id
        name
      }
      purpose_ko
      title
      category_ko
      status_ko
      status_color
      is_manageable
    }
  }
`;

export default function UserReservations() {
  const history = useHistory();
  const params = qs.parse(history.location.search);
  const page = params.page ? Number(params.page) : 1;
  const { loading, data } = useQuery(GET_USER_RESERVATIONS, {
    variables: { offset: 20 * (page - 1) },
  });
  if (loading)
    return (
      <div className="pv-3">
        <Container text textAlign="center">
          <ReservationTab />
        </Container>
      </div>
    );
  if (!data?.userReservations)
    return (
      <div className="pv-3">
        <Container text textAlign="center">
          <ReservationTab />
        </Container>
      </div>
    );

  const items = data.userReservations.map(reservation => (
    <ReservationItem reservation={reservation} key={reservation.id} />
  ));

  return (
    <div className="bg-white pv-3">
      <Container text textAlign="center">
        <ReservationTab />

        {data.userReservations < 1 ? (
          <>
            <p>아직 대관 신청내역이 없습니다.</p>
            <Button
              as={Link}
              to={{ pathname: '/reservations/covid19_guide' }}
              size="large"
              content="신청하기"
              primary
            />
          </>
        ) : (
          <Grid verticalAlign="middle" divided="vertically" className="ta-l mt-2">
            {items}
          </Grid>
        )}

        <UserReservationsPagination history={history} />
      </Container>
    </div>
  );
}
