import moment from 'moment';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import { Button, Modal } from 'semantic-ui-react';
import gql from 'graphql-tag';

const CANCEL_RESERVATION = gql`
  mutation cancelReservation($id: ID!) {
    cancelReservation(id: $id) {
      id
      status
      status_ko
      status_color
    }
  }
`;

export default function ReservationCancelButton({ reservation }) {
  const [modalVisible, setModalVisible] = useState(false);
  const history = useHistory();
  const [cancelReservation, { loading }] = useMutation(CANCEL_RESERVATION);

  if (reservation && !reservation.is_manageable) return null;
  if (reservation && ['cancelled', 'rejected'].includes(reservation.status)) return null;
  return (
    <>
      <Button
        size="large"
        content="신청 취소"
        loading={loading}
        disabled={loading}
        onClick={() => {
          if (
            moment(reservation.end_datetime * 1000) < moment(Date.now()).add(6, 'day') &&
            reservation.status === 'approved'
          ) {
            setModalVisible(true);
            return;
          }
          if (window.confirm('정말로 대관 신청을 취소하겠습니까?')) {
            cancelReservation({
              variables: { id: reservation.id },
            })
              .then(response => {
                alert('대관 신청이 취소되었습니다.');
                history.push(`/reservations/${response.data.cancelReservation.id}`);
              })
              .catch(error => {
                alert(error.graphQLErrors && error.graphQLErrors[0].message);
              });
          }
        }}
      />
      <Modal onClose={() => setModalVisible(false)} open={modalVisible} size="tiny">
        <Modal.Header content="신청 취소" />
        <Modal.Content>
          <p>
            {'운영 원칙 상 1주일 이내 행사는 취소가 불가합니다. 부득이하게 취소를 해야할 경우 '}
            <a href="mailto:info@changemakers.xyz" data-rel="external" className="fw-b">
              info@changemakers.xyz
            </a>
            {' 이메일로 문의해주십시오. No-show 발생 시, 패널티가 있음을 알려드립니다.'}
          </p>
        </Modal.Content>
        <Modal.Actions />
      </Modal>
    </>
  );
}

ReservationCancelButton.propTypes = {
  reservation: PropTypes.shape({
    id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    end_datetime: PropTypes.number.isRequired,
    is_manageable: PropTypes.bool,
  }),
};
