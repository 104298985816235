import React from 'react';

const Section = ({ children, className, darker, url }) => {
  return (
    <div
      className={`section-table${className ? ` ${className}` : ''}`}
      style={{
        backgroundImage: `url(${url})`
      }}
    >
      <div className={`section-tablecell${darker ? ' darker' : ''}`}>
        {children}
      </div>
    </div>
  );
};

export default Section;
