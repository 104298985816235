import React from 'react';
import { Container, Header } from 'semantic-ui-react';

export default function ReservationGuide() {
  return (
    <div className="bg-white bt-1 pv-5">
      <Container text textAlign="center">
        <Header as="h2" content="대관 운영 종료 안내" />
        <p className="ta-l">
          아쇼카 한국이 체인지메이킹 무브먼트를 위해 운영하던 체인지메이커스의 대관 운영을
          종료합니다. 그동안 체인지메이커스를 이용해주신 체인지메이커 여러분께 깊은 감사를 드리며,
          자세한 이후 계획은 공지를 통해 알리도록 하겠습니다.
          <br />
        </p>
        <p className="ta-l">새로운 공간의 탄생을 위해 많은 기대와 응원 부탁드립니다.</p>
      </Container>
    </div>
  );
}
