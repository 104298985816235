import React, { Fragment } from 'react';
import { Container, Header, List } from 'semantic-ui-react';
import { MetaData } from '../commons';
import { PoliciesTab } from '.';

const Terms = ({ match }) => {
  return (
    <Fragment>
      <div className="bg-white pv-3">
        <Container text textAlign="center">
          <PoliciesTab url={match.url} />
          <Header as="h2" content="이용약관" />
          <div className="ta-l">
            <Header as="h3" className="mb-q" content="제 1조 (목적)" />
            <p>
              본 약관은 아쇼카 한국(이하 “아쇼카”라 한다)이 운영하는
              체인지메이커스(이하 “체인지메이커스”라 한다) 홈페이지가 서비스를
              제공하고 이용자가 이를 이용함에 있어 체인지메이커스와 이용자의
              권리와 의무 및 준수사항을 규정함을 목적으로 합니다.
            </p>

            <Header
              as="h3"
              className="mb-q"
              content="제 2조 (약관의 효력과 개정)"
            />
            <List ordered>
              <List.Item>
                아쇼카는 홈페이지에 새로운 서비스의 도입이나 정책상의 이유, 또는
                특별한 사정이 있는 경우 약관을 사전 고지 없이 개정할 수 있으며,
                변경된 약관은 제10조에 정한 방법으로 공지합니다. 다만 이용자에게
                불리한 약관 변경의 경우 1개월 전에 공지합니다.
              </List.Item>
              <List.Item>
                회원은 개정된 약관에 동의하지 아니하는 경우 회원등록을
                취소(회원탈퇴)할 수 있으며, 아쇼카가 개정된 약관을 고지하면서
                1주일 이내에 거부의 의사표시를 하지 아니하면 동의한 것으로
                본다는 뜻을 명확하게 알렸음에도 그 기간 내에 회원이 명시적으로
                거부의 의사표시를 하지 않거나 계속 사용의 경우는 약관 개정에
                동의하는 것으로 간주됩니다.
              </List.Item>
              <List.Item>
                변경된 약관은 공지 후 1주일이 지난 때, 이용자에게 불리한 변경의
                경우는 공지 후 1개월이 지난 때에 그 효력이 발생됩니다.
              </List.Item>
            </List>

            <Header
              as="h3"
              className="mb-q"
              content="제 3조 (약관 이외의 준칙)"
            />
            <p>
              본 약관에 명시되지 아니한 사항에 대해서는 약관의 규제에 관한 법률,
              전자문서 및 전자거래기본법, 전자금융거래법, 전자서명법, 정보통신망
              이용촉진 및 정보보호 등에 관한 법률, 개인정보 보호법 및 기타 관련
              법령에서 정하는 바에 의하기로 합니다.
            </p>

            <Header as="h3" className="mb-q" content="제 4조 (용어의 정의)" />
            <p>이 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p>
            <List ordered>
              <List.Item>
                홈페이지: 체인메이커스 홈페이지 및 그에 직접 부속된 웹페이지를
                말하고, 외부 링크된 홈페이지 및 웹페이지를 포함하지 않습니다.
              </List.Item>
              <List.Item>
                회원: 약관에 따라 홈페이지에 개인정보를 제공하여 회원등록을 한
                자로서, 아쇼카에서 제공하는 홈페이지 내 정보를 계속적으로 이용할
                수 있는 자를 말합니다.
              </List.Item>
              <List.Item>
                이용자: 홈페이지에서 제공하는 서비스를 이용하는 자로, 회원뿐
                아니라 비회원도 포함합니다.
              </List.Item>
              <List.Item>
                이메일: 회원의 식별과 원활한 서비스 이용을 위하여 회원이
                회원등록 시에 정하고 아쇼카가 승인하는 회원 고유의 전자우편
                주소를 말합니다.
              </List.Item>
              <List.Item>
                비밀번호: 홈페이지 접속자가 특정 이메일 주소로 식별되는 회원과
                일치한 자임을 확인하기 위하여 회원이 선정한 문자와 숫자의 조합을
                말합니다.
              </List.Item>
            </List>
            <Header as="h3" className="mb-q" content="제 5조 (회원가입)" />
            <List ordered>
              <List.Item>
                아쇼카는 홈페이지에 새로운 서비스의 도입이나 정책상의 이유, 또는
                특별한 사정이 있는 경우 약관을 사전 고지 없이 개정할 수 있으며,
                변경된 약관은 제10조에 정한 방법으로 공지합니다. 다만 이용자에게
                불리한 약관 변경의 경우 1개월 전에 공지합니다.
              </List.Item>
              <List.Item>
                이용자는 아쇼카가 정한 가입양식에 따라 회원정보를 기입한 후 이
                약관에 동의한다는 의사표시를 함으로서 회원가입을 신청합니다.
              </List.Item>
              <List.Item>
                이용자는 본인사용의 1개 이메일 주소에 대해 1건의 회원가입신청을
                할 수 있습니다.
              </List.Item>
            </List>
            <Header as="h3" className="mb-q" content="제 6조 (가입제한)" />
            <p>
              아쇼카는 회원으로 가입할 것을 신청한 이용자가 다음 각 항에
              해당하는 경우 회원 가입을 거절할 수 있습니다.
            </p>
            <List ordered>
              <List.Item>이메일 주소가 유효하지 않은 경우</List.Item>
              <List.Item>
                등록 내용에 허위, 기재누락, 오기가 있는 경우
              </List.Item>
              <List.Item>
                타인의 정보 또는 명의를 사용하여 신청한 경우
              </List.Item>
              <List.Item>
                가입신청자가 이 약관 제7조 3항에 의하여 이전에 회원자격을 상실한
                적이 있는 경우
              </List.Item>
              <List.Item>
                이용자가 아쇼카나 아쇼카가 제공하는 서비스에 위해를 초래할
                우려가 있는 경우
              </List.Item>
            </List>
            <Header
              as="h3"
              className="mb-q"
              content="제 7조 (회원탈퇴 및 자격상실)"
            />
            <List ordered>
              <List.Item>
                회원은 언제든지 회원의 탈퇴를 홈페이지를 통해 요청할 수 있으며,
                아쇼카는 이에 응합니다.
              </List.Item>
              <List.Item>
                회원이 다음 각 호의 사유에 해당하는 경우, 아쇼카는 회원자격을
                제한∙정지∙박탈할 수 있습니다.
                <List.List>
                  <List.Item>가입 신청 시에 허위 내용을 등록한 경우</List.Item>
                  <List.Item>
                    타인의 서비스 이용을 방해하거나 그 정보를 도용하는 등 서비스
                    운영질서를 위협하는 경우
                  </List.Item>
                  <List.Item>
                    서비스를 이용하여 법령과 이 약관이 금지하거나, 공서양속에
                    반하는 행위를 하는 경우
                  </List.Item>
                  <List.Item>
                    제13조에 명기된 회원의 의무사항을 준수하지 않을 경우
                  </List.Item>
                </List.List>
              </List.Item>
              <List.Item>
                아쇼카가 회원자격을 박탈할 경우 회원에게 이를 전자우편 등 적당한
                방법으로 통지하고 탈퇴 처리합니다.
              </List.Item>
            </List>

            <Header
              as="h3"
              className="mb-q"
              content="제 8조 (서비스의 제공 및 변경)"
            />
            <List ordered>
              <List.Item>
                아쇼카가 홈페이지를 통해 제공하는 서비스는 다음과 같습니다.
                <List.List>
                  <List.Item>
                    아쇼카 및 체인지메이커스에 대한 홍보 내용
                  </List.Item>
                  <List.Item>
                    아쇼카 및 체인지메이커스가 운영하는 사업 및 행사 안내
                  </List.Item>
                  <List.Item>
                    체인지메이커스 시설 및 공간 대관 신청 안내 서비스
                  </List.Item>
                  <List.Item>
                    기타 아쇼카 및 체인지메이커스의 업무와 관련한 각종 정보 제공
                  </List.Item>
                </List.List>
              </List.Item>
              <List.Item>
                아쇼카는 필요한 경우 서비스의 내용을 추가 또는 변경하여 제공할
                수 있습니다.
              </List.Item>
            </List>

            <Header as="h3" className="mb-q" content="제 9조 (서비스의 중단)" />
            <List ordered>
              <List.Item>
                아쇼카는 컴퓨터 등 정보통신설비의 보수점검, 교체 , 고장 및
                통신두절 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로
                중단하거나 제한할 수 있습니다.
              </List.Item>
              <List.Item>
                서비스 중단, 제한의 경우에는 제10조에 정한 방법으로 이용자에게
                통지합니다.
              </List.Item>
              <List.Item>
                아쇼카는 위의 사유로 서비스의 제공이 일시적으로 중단되거나
                제한됨으로 인하여 이용자 또는 제3자가 입은 손해에 대하여
                배상하지 아니합니다.
              </List.Item>
              <List.Item>
                단, 아쇼카의 고의 또는 중과실이 있는 경우에는 그러하지
                아니합니다.
              </List.Item>
            </List>

            <Header
              as="h3"
              className="mb-q"
              content="제 10조 (이용자에 대한 통지)"
            />
            <List ordered>
              <List.Item>
                특정 이용자에 대한 통지를 하는 경우, 아쇼카는 이용자가 제출한
                이메일로 통지할 수 있습니다.
              </List.Item>
              <List.Item>
                불특정 다수 이용자에 대한 통지를 하는 경우 아쇼카는 홈페이지
                게시판에 1주일 이상 기간 동안 그 내용을 게시함으로써 개별 통지에
                갈음할 수 있습니다.
              </List.Item>
            </List>

            <Header
              as="h3"
              className="mb-q"
              content="제 11조 (개인정보보호 의무)"
            />
            <List ordered>
              <List.Item>
                아쇼카는 정보통신망 이용촉진 및 정보보호 등에 관한 법률,
                개인정보 보호법 등 관계 법령이 정하는 바에 따라 이용자의
                개인정보를 보호하기 위해 노력합니다.
              </List.Item>
              <List.Item>
                아쇼카는 개인정보 처리방침을 제정하여 이에 따라 이용자의
                개인정보를 보유 및 이용합니다.
              </List.Item>
              <List.Item>
                다만, 홈페이지에 외부 링크된 사이트에서는 아쇼카의 개인정보
                처리방침이 적용되지 않습니다.
              </List.Item>
            </List>

            <Header
              as="h3"
              className="mb-q"
              content="제 12조 (아쇼카의 의무)"
            />
            <List ordered>
              <List.Item>
                아쇼카는 이 약관에 정한 바에 따라 계속적, 안정적으로 서비스를
                제공할 수 있도록 최선의 노력을 다하여야 합니다.
              </List.Item>
              <List.Item>
                아쇼카는 서비스에 관련된 설비를 항상 운용할 수 있는 상태로 유지
                및 보수하고, 장애가 발생하는 경우 지체 없이 이를 수리 및 복구할
                수 있도록 최선의 노력을 다하여야 합니다.
              </List.Item>
              <List.Item>
                아쇼카는 이용자가 안전하게 서비스를 이용할 수 있도록 이용자의
                개인정보보호를 위한 보안시스템을 갖추어야 합니다.
              </List.Item>
              <List.Item>
                아쇼카는 이용자가 원하지 않는 영리목적의 광고성 전자우편을
                발송하지 않습니다.
              </List.Item>
            </List>

            <Header
              as="h3"
              className="mb-q"
              content="제 13조 (회원의 이메일 및 비밀번호에 대한 의무)"
            />
            <List ordered>
              <List.Item>
                회원이 가입 시 제공한 이메일과 비밀번호를 관리할 책임은 회원에게
                있으며 관리소홀, 부정사용에 의하여 발생하는 모든 결과에 대한
                책임은 회원에게 있습니다.
              </List.Item>
              <List.Item>
                이메일은 원칙적으로 변경이 불가하며 부득이한 사유로 인하여
                변경하고자 하는 경우에는 홈페이지에 재가입해야 합니다.
              </List.Item>
              <List.Item>
                회원이 아쇼카가 운영하는 다른 멤버십 홈페이지에 가입하려는 경우
                해당 홈페이지에 직접 가입하여야 하며, 이 경우 해당 이용약관과
                개인정보 보호정책을 적용 받습니다.
              </List.Item>
            </List>

            <Header as="h3" className="mb-q" content="제 14조 (회원의 의무)" />
            <List ordered>
              <List.Item>
                회원은 관계법령, 이 약관의 규정, 이용안내 및 주의사항 등
                아쇼카가 통지하는 사항을 준수하여야 하며, 기타 아쇼카의 업무에
                방해되는 행위를 하여서는 안됩니다.
              </List.Item>
              <List.Item>
                회원은 아쇼카의 사전승낙 없이 홈페이지 및 홈페이지를 통해
                제공하는 서비스를 이용하여 어떠한 영리행위도 할 수 없습니다.
              </List.Item>
              <List.Item>
                회원은 홈페이지를 이용하여 얻은 정보를 이쇼카의 사전승낙 없이
                복사, 복제, 변경, 번역, 출판, 방송 기타의 방법으로 사용하거나
                이를 타인에게 제공할 수 없습니다.
              </List.Item>
              <List.Item>
                회원은 회원가입 시에 기재한 정보가 변경되면 즉각 홈페이지 내에서
                이를 수정하여야 합니다.
              </List.Item>
              <List.Item>
                회원정보를 수정하지 않아 발생하는 모든 결과에 대한 책임은
                회원에게 있습니다.
              </List.Item>
              <List.Item>
                회원은 서비스 이용과 관련하여 다음 각 호의 행위를 하지 않아야
                하며, 다음 행위를 함으로 발생하는 모든 결과에 대한 책임은
                회원에게 있습니다.
              </List.Item>
              <List ordered>
                <List.Item>
                  다른 회원의 이메일 주소를 취득하여 스팸 메일을 발송하는 행위
                </List.Item>
                <List.Item>
                  범죄행위를 목적으로 하거나 기타 범죄행위와 관련된 행위
                </List.Item>
                <List.Item>선량한 풍속, 기타 사회질서를 해하는 행위</List.Item>
                <List.Item>
                  아쇼카 및 타인의 명예를 훼손하거나 모욕하는 행위
                </List.Item>
                <List.Item>
                  아쇼카 및 타인의 지적재산권 등의 권리를 침해하는 행위
                </List.Item>
                <List.Item>해킹행위 또는 컴퓨터 바이러스의 유포행위</List.Item>
                <List.Item>
                  타인의 의사에 반하여 광고성 정보 등 일정한 내용을 지속적으로
                  전송하는 행위
                </List.Item>
                <List.Item>
                  서비스의 안정적인 운영에 지장을 주거나 줄 우려가 있는 일체의
                  행위
                </List.Item>
                <List.Item>
                  아쇼카가 제공하는 서비스의 내용을 변경하는 행위
                </List.Item>
                <List.Item>기타 관계법령에 위배되는 행위</List.Item>
              </List>
            </List>

            <Header
              as="h3"
              className="mb-q"
              content="제 15조 (게시물에 대한 권리/의무)"
            />
            <List ordered>
              <List.Item>
                이용자가 홈페이지 내에 게시한 게시물의 저작권은 해당 게시물의
                저작자에게 귀속됩니다.
              </List.Item>
              <List.Item>
                이용자가 홈페이지 내에 게시하는 게시물은 아쇼카 홈페이지 내
                검색결과, 외부 검색포털을 사용한 검색결과 내지 아쇼카의 프로모션
                등에 노출될 수 있으며, 해당 노출을 위해 필요한 범위 내에서는
                일부가 수정, 복제, 편집되어 게시될 수 있습니다.
              </List.Item>
              <List.Item>
                이 경우 아쇼카는 저작권법 규정을 준수하며, 이용자는 언제든지
                고객센터 또는 서비스 내 관리기능을 통해 해당 게시물을 삭제, 수정
                등의 조치를 취할 수 있습니다.
              </List.Item>
              <List.Item>
                아쇼카는 위 사항 이외의 방법으로 이용자의 게시물을 이용하고자
                하는 경우에는 전화, 전자우편 등을 통해 사전에 동의를 얻어야
                합니다.
              </List.Item>
            </List>

            <Header
              as="h3"
              className="mb-q"
              content="제 16조 ('연결 홈페이지'와 '피연결 홈페이지'간의 관계)"
            />
            <List ordered>
              <List.Item>
                상위 홈페이지와 하위 홈페이지가 하이퍼 링크(예: 하이퍼 링크의
                대상에는 문자, 그림 및 동영상 등이 포함됨) 방식 등으로 연결된
                경우, 전자를 “연결 홈페이지”라고 하고 후자를 “피연결
                홈페이지”라고 합니다.
              </List.Item>
              <List.Item>
                “연결 홈페이지”는 “피연결 홈페이지”가 독자적으로 제공하는
                재화∙용역에 의하여 이용자와 행하는 거래에 대해서 일체의 책임을
                부담하지 않습니다.
              </List.Item>
            </List>

            <Header
              as="h3"
              className="mb-q"
              content="제 17조 (저작권의 귀속 및 이용제한)"
            />
            <List ordered>
              <List.Item>
                아쇼카가 작성한 저작물에 대한 저작권 및 기타 지적재산권은
                아쇼카에 귀속됩니다.
              </List.Item>
              <List.Item>
                이용자는 홈페이지를 이용함으로써 얻은 정보를 아쇼카의 사전승낙
                없이 복제, 송신, 출판, 배포, 방송, 기타 방법에 의하여
                영리목적으로 이용하거나 제 3자에게 이용하게 하여서는 안됩니다.
              </List.Item>
            </List>

            <Header as="h3" className="mb-q" content="제 18조 (양도금지)" />
            <p>
              회원은 홈페이지의 이용권한, 기타 이용 계약상 지위를 타인에게 양도,
              증여할 수 없으며, 이를 담보로 제공할 수 없습니다.
            </p>

            <Header as="h3" className="mb-q" content="제 19조 (손해배상)" />
            <p>
              아쇼카는 무료로 제공되는 서비스와 관련하여 이용자와 회원에게
              어떠한 손해가 발생하더라도 동 손해가 아쇼카의 중대한 과실에 의한
              경우를 제외하고는 이에 대하여 책임을 부담하지 아니합니다.
            </p>

            <Header as="h3" className="mb-q" content="제 20조 (면책/배상)" />
            <List ordered>
              <List.Item>
                아쇼카는 이용자가 홈페이지에 게재한 정보, 자료, 사실의 정확성,
                신뢰성 등 그 내용에 관하여는 어떠한 책임도 부담하지 아니합니다.
              </List.Item>
              <List.Item>
                이용자는 자신의 책임 아래 서비스를 이용하며, 서비스를 이용하여
                게시 또는 전송한 자료 등에 관하여 손해가 발생하거나 자료의
                취사선택, 기타 서비스 이용과 관련하여 어떠한 불이익이
                발생하더라도 이에 대한 모든 책임은 이용자에게 있습니다.
              </List.Item>
              <List.Item>
                아쇼카는 이용자 간 또는 이용자와 제3자 간에 제14조의 규정에
                위반하여 홈페이지를 매개로 행한 물품거래 등과 관련하여 어떠한
                책임도 부담하지 아니하고, 이용자가 홈페이지의 이용과 관련하여
                기대하는 일체의 경제적 이익에 관한 책임을 부담하지 않습니다.
              </List.Item>
              <List.Item>
                이용자가 제14조, 기타 이 약관의 규정을 위반함으로 인하여
                아쇼카가 제3자에 대하여 책임을 부담하게 되고, 이로써 아쇼카에게
                손해가 발생하게 되는 경우, 이 약관을 위반한 이용자는 아쇼카에게
                발생하는 모든 손해를 배상하여야 하며, 동 손해로부터 아쇼카를
                면책시켜야 합니다.
              </List.Item>
            </List>

            <Header as="h3" className="mb-q" content="제 21조 (분쟁의 해결)" />
            <p>
              아쇼카와 이용자는 서비스와 관련하여 발생한 분쟁을 원만하게
              해결하기 위하여 필요한 모든 노력을 다 하되 해결할 수 없는 경우
              아쇼카의 본점 소재지를 관할하는 법원을 전속관할 법원으로 하여 그
              판단에 따릅니다.
            </p>

            <Header as="h3" className="mb-q" content="부칙" />
            <p>이 약관은 2017년 12월 1일부터 시행합니다.</p>
          </div>
        </Container>
      </div>
      <MetaData title="체인지메이커스 이용약관" />
    </Fragment>
  );
};

export default Terms;
