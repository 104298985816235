import React from 'react';
import { Table } from 'semantic-ui-react';

const TableRow = ({ title, children }) => {
  return (
    <Table.Row>
      <Table.Cell width={3} className="pr-0" verticalAlign="top">
        <span className="gray">{title}</span>
      </Table.Cell>
      <Table.Cell className="text" style={{ maxWidth: 0 }} verticalAlign="top" width={13}>
        {children}
      </Table.Cell>
    </Table.Row>
  );
};

export default TableRow;
