import React from 'react';
import { Container, Grid, Header, Icon, Image } from 'semantic-ui-react';
import billDrayton from '../images/bill_drayton.jpg';
import { coverChangemakers } from '../images/cover';
import {
  ashoka,
  futureclassnet,
  gonggamin,
  heyground,
  hyundai,
  mta,
  rootimpact,
  jump,
  hanyang,
  awesomeschool,
  opencollege,
} from '../images/logo';
import { Footer, MetaData, Section } from './commons';

export default function Changemakers() {
  return (
    <>
      <Section url={coverChangemakers} />
      <div className="bg-white pv-5">
        <Container text textAlign="center">
          <Header as="h2">
            체인지메이커스:
            <br className="visible-xxs" />
            공감을 경험하고 영감을 얻는 공간
          </Header>
          <p className="ta-l">
            체인지메이커스는 자라나는 세대부터 혁신가들까지 모두가 공감을 경험하고 영감을 얻을 수
            있는 공간이자, 나를 변화시키고, 우리를 변화시키며, 세상을 변화시켜가는 체인지메이커들의
            안전지대입니다. 이 곳은 혁신가들의 열린 아지트, 변화하는 커뮤니티 컨셉을 담아 디자인
            되었습니다.
          </p>
          <p className="small gray ta-l">
            체인지메이커스는 ‘모두가 체인지메이커(Everyone A Changemaker™)’인 세상을 앞당기는 글로벌
            무브먼트 Ashoka의 한국법인 아쇼카 한국이 운영합니다.
          </p>
        </Container>
      </div>

      <div className="bg-whitesmoke pv-5 bt-1">
        <Container text textAlign="center">
          <Header as="h2" content="체인지메이커" />
          <p className="ta-l">
            오늘날 세상은 급속도로 변화하고 있으며 사회 문제도 점점 다양해지고 심화되고 있습니다.
            그렇기에 우리는 지금과는 다른 방식의 삶을 상상할 수 있어야 하고, 일하는 방식에도 변화를
            주어야 합니다. 개인, 비지니스, 조직, 지역 사회, 국가 모두는 기존의 틀을 넘어서는
            창의적인 방식으로 긍정적인 변화를 만들어 낼 수 있어야 합니다.
          </p>
          <p className="ta-l">
            모두가 체인지메이커(Everyone A Changemaker™)인 세상에서는 모든 사람들이 유연성, 창의성,
            공감 능력을 갖추고 문제에 빠르고 자신 있게 대응할 수 있고, 어떠한 도전에도 협력적인
            리더십과 팀워크를 발휘하여 위기를 기회로 바꿀 수 있습니다.
          </p>
          <p className="ta-l">
            빠르게 변화하는 세상에서 체인지메이커들은 변화의 ‘대상’이 되기보다는 문제를 해결하고 한
            단계 전진하는 변화의 ‘주체’가 되기를 선택하는 사람들 입니다.
          </p>
          <Grid verticalAlign="middle" className="mt-2">
            <Grid.Column mobile={5} tablet={3} computer={3} largeScreen={3}>
              <Image src={billDrayton} circular verticalAlign="middle" />
            </Grid.Column>
            <Grid.Column mobile={11} tablet={11} computer={11} largeScreen={11}>
              <Header as="h4" className="ta-l lightgray mb-h">
                <Icon name="quote left" />
              </Header>
              <p className="ta-l">
                회사이든, 국가이든, 어떠한 그룹의 주요한 ‘성공’ 척도는 새로운 아이디어를 제시하고
                행동을 취하며, 그것을 이끌어 나갈 체인지메이커들이 얼마나 많이 있느냐에
                달려있습니다.
              </p>
              <p className="small gray ta-l">— 빌 드레이튼, 아쇼카 설립자</p>
            </Grid.Column>
            <Grid.Column only="tablet computer" tablet={2} computer={2} largeScreen={2} />
          </Grid>
        </Container>
      </div>

      <div className="bg-white pv-5 bt-1">
        <Container text textAlign="center">
          <Image size="medium" src={ashoka} verticalAlign="middle" />
          <Header as="h2" content="아쇼카" />
          <p className="ta-l">
            아쇼카는 전 세계 93개국에서 활동하는 4,000명 이상의 ‘소셜 앙트러프러너(Social
            Entrepreneur)’와 수많은 ‘체인지메이커(changemaker)’들을 위한 세계 최대 비영리 글로벌
            네트워크 조직입니다. 새로운 아이디어를 통해 본인이 속한 커뮤니티와 더 나아가 세상의
            문제를 해결하는 뛰어난 사회혁신 기업가들을 아쇼카 펠로우로 선정 · 지원하고 있으며,
            사회의 문화와 마인드셋의 변화를 통해 ‘Everyone A Changemaker™’ 무브먼트를 이어가고
            있습니다.
          </p>
          <p className="ta-l">
            아쇼카 한국은 2013년 공식 출범 이후 교육, 지역 재생, 인권, 정신건강, 의료보건 등 다양한
            분야에서 15명의 펠로우를 선정하였으며, 국내외 네트워크를 통해 그들의 혁신적인 아이디어를
            공유하고 임팩트를 지원하며 새로운 방식으로 한국 사회의 변화를 이끌고 있습니다. 나아가
            청소년, 대학, 기업 등 사회 각계각층이 주도적인 문제 해결사가 되도록 도움으로써, 한국이
            ‘모두가 체인지메이커’인 사회로 발돋움하는데 기여하고자 합니다.
          </p>
        </Container>
      </div>

      <div className="bg-whitesmoke pv-5 bt-1">
        <Container text textAlign="center">
          <Header as="h2" content="아쇼카 한국과 체인지메이커스" />
          <p className="ta-l">
            아쇼카 한국은 세상의 변화를 꿈꾸는 사람들이 함께 모여 일하는 공간인 성수동
            헤이그라운드(HEYGROUND)의 대표 모임 공간인 ‘체인지메이커스 (earth, us, changemakers)’를
            운영하고 있습니다. 체인지메이커스는 자라나는 세대부터 혁신가들까지 다양한
            체인지메이커들이 서로 만나서 공감능력, 팀워크, 협력적 리더십, 체인지메이킹을 배우고
            적용하고 실행할 수 있는 커뮤니티 공간입니다.
          </p>
        </Container>
      </div>

      <div className="bg-white bt-1 pv-3">
        <Container text textAlign="center">
          <Header as="h2">
            후원
            <Header.Subheader className="mt-h">
              {'체인지메이커스 공간은 '}
              <br className="visible-xxs" />
              현대해상화재보험의 후원으로 운영됩니다.
            </Header.Subheader>
          </Header>
          <Grid padded>
            <Grid.Row>
              <Grid.Column mobile={1} tablet={4} computer={6} largeScreen={6} />
              <Grid.Column mobile={14} tablet={8} computer={4} largeScreen={4}>
                <Image src={hyundai} size="small" verticalAlign="middle" />
              </Grid.Column>
              <Grid.Column mobile={1} tablet={4} computer={6} largeScreen={6} />
            </Grid.Row>
          </Grid>
        </Container>
      </div>

      <div className="bg-whitesmoke bt-1 pv-3">
        <Container text textAlign="center">
          <Header as="h2">
            파트너
            <Header.Subheader className="mt-h">
              체인지메이커스는 체인지메이킹 무브먼트의 확산에 동참하고 있는
              <br className="hidden-xxs" />
              펠로우 단체 및 Team of Teams 등 다양한 파트너들에게 열려 있습니다.
            </Header.Subheader>
          </Header>
        </Container>
        <Container textAlign="center">
          <Grid verticalAlign="middle">
            <Grid.Row>
              <Grid.Column only="tablet computer" tablet={2} computer={2} largeScreen={2} />
              <Grid.Column mobile={8} tablet={3} computer={3} largeScreen={3}>
                <Image src={gonggamin} size="small" verticalAlign="middle" />
              </Grid.Column>
              <Grid.Column mobile={8} tablet={3} computer={3} largeScreen={3}>
                <Image src={futureclassnet} size="small" verticalAlign="middle" />
              </Grid.Column>
              <Grid.Column mobile={8} tablet={3} computer={3} largeScreen={3}>
                <Image src={mta} size="small" verticalAlign="middle" />
              </Grid.Column>
              <Grid.Column mobile={8} tablet={3} computer={3} largeScreen={3}>
                <Image src={rootimpact} size="small" verticalAlign="middle" />
              </Grid.Column>
              <Grid.Column only="tablet computer" tablet={2} computer={2} largeScreen={2} />

              <Grid.Column only="tablet computer" tablet={2} computer={2} largeScreen={2} />
              <Grid.Column mobile={8} tablet={3} computer={3} largeScreen={3}>
                <Image src={jump} size="small" verticalAlign="middle" />
              </Grid.Column>
              <Grid.Column mobile={8} tablet={3} computer={3} largeScreen={3}>
                <Image src={hanyang} size="small" verticalAlign="middle" />
              </Grid.Column>
              <Grid.Column mobile={8} tablet={3} computer={3} largeScreen={3}>
                <Image src={awesomeschool} size="small" verticalAlign="middle" />
              </Grid.Column>
              <Grid.Column mobile={8} tablet={3} computer={3} largeScreen={3}>
                <Image src={opencollege} size="small" verticalAlign="middle" />
              </Grid.Column>
              <Grid.Column only="tablet computer" tablet={2} computer={2} largeScreen={2} />

              <Grid.Column only="tablet computer" tablet={2} computer={2} largeScreen={2} />
              <Grid.Column mobile={8} tablet={3} computer={3} largeScreen={3}>
                <Image src={heyground} size="small" verticalAlign="middle" />
              </Grid.Column>
              <Grid.Column only="tablet computer" tablet={2} computer={2} largeScreen={2} />
            </Grid.Row>
          </Grid>
        </Container>
      </div>
      <Footer />
      <MetaData
        title="체인지메이커스"
        description="체인지메이커스(earth.us.changemakers)는 자라나는 세대부터
            혁신가들까지 모두가 공감을 경험하고 영감을 얻을 수 있는 공간입니다.
            이 공간은 나를 변화시키고, 우리를 변화시키며, 세상을 변화시켜가는
            체인지메이커들의 안전 지대입니다. 혁신가들의 열린 아지트, 변화하는
            커뮤니티의 컨셉을 담아 디자인 되었습니다."
      />
    </>
  );
}
