import React from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Redirect } from 'react-router-dom';
import { Dimmer, Loader } from 'semantic-ui-react';
import { ReservationForm } from '.';
import { ReservationQuery, RESERVATION_FRAGMENT } from '../../graphql/reservations';

const UPDATE_RESERVATION = gql`
  mutation updateReservation(
    $id: ID!
    $space_ids: [Int]
    $start_datetime: Int
    $end_datetime: Int
    $category: String
    $name: String
    $institution: String
    $email: String
    $phone: String
    $title: String
    $description: String
    $number_of_participants: Int
    $purpose: String
    $is_public: Boolean
    $cost: Int
    $microphone: Boolean
    $projector: Boolean
    $sound_equipment: Boolean
    $equipment_request: String
    $catering: Boolean
    $banner: Boolean
    $promotion: Boolean
    $website: String
    $agenda_signed_id: String
    $status: String
  ) {
    updateReservation(
      id: $id
      agenda_signed_id: $agenda_signed_id
      reservation: {
        space_ids: $space_ids
        start_datetime: $start_datetime
        end_datetime: $end_datetime
        category: $category
        name: $name
        institution: $institution
        email: $email
        phone: $phone
        title: $title
        description: $description
        number_of_participants: $number_of_participants
        purpose: $purpose
        is_public: $is_public
        cost: $cost
        microphone: $microphone
        projector: $projector
        sound_equipment: $sound_equipment
        equipment_request: $equipment_request
        catering: $catering
        banner: $banner
        promotion: $promotion
        website: $website
        status: $status
      }
    ) {
      ...ReservationFragment
    }
  }
  ${RESERVATION_FRAGMENT}
`;

function EditReservation({ match, history }) {
  const { loading, error, data } = useQuery(ReservationQuery, {
    variables: { id: match.params.id },
  });

  const [updateReservation, { loading: updateReservationLoading }] = useMutation(
    UPDATE_RESERVATION,
  );

  if (error) return null;
  if (loading)
    return (
      <Dimmer active inverted>
        <Loader active inline="centered" />
      </Dimmer>
    );
  if (!data || !data.reservation) return null;
  if (data.reservation.status !== 'pending') return <Redirect to="/" />;
  return (
    <ReservationForm
      reservation={data.reservation}
      loading={updateReservationLoading}
      submit={updateReservation}
      history={history}
    />
  );
}

export default EditReservation;
