import React from 'react';
export const StatusContext = React.createContext();

export function withStatus(Component) {
  return function ThemedComponent(props) {
    return (
      <StatusContext.Consumer>
        {status => <Component {...props} status={status} />}
      </StatusContext.Consumer>
    );
  };
}
