import React from 'react';
import { Button, Container, Divider, Grid, Header, Image, List } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { MetaData, Section, Footer } from '../commons';
import ReservationGuide from './ReservationGuide';
import { coverSpaces } from '../../images/cover';
import { space1, space2 } from '../../images/spaces';
import { Calendar } from '../reservations';

export default function Spaces() {
  return (
    <>
      <Section url={coverSpaces} />
      <div className="bg-white pv-5">
        <Container text textAlign="center">
          <Header as="h2" content="체인지메이커들의 안전지대" />
          <p className="ta-l">
            성수동 헤이그라운드 지하 1층에 위치한 모임 공간 체인지메이커스는 체인지메이킹 문화 확산
            운동의 커뮤니티 허브입니다. 공감능력과 체인지메이킹 문화 확산의 상징적인 아지트로서,
            영역과 세대를 구분하지 않고 모든 체인지메이커에게 열려있습니다.
          </p>
        </Container>
      </div>

      <div className="bg-whitesmoke pv-5 bt-1">
        <Container textAlign="center" className="calendar-container">
          <Header as="h2" content="공간 구성" />
          <Grid columns={2} stackable className="ta-l mt-2">
            <Grid.Row>
              <Grid.Column>
                <Image src={space1} />
                <Header as="h3" content="다목적홀 '영감 Inspiration'" className="mt-1 mb-q" />
                <p className="text mb-h">
                  체인지메이킹을 위한 영감을 얻을 수 있는 대규모 개방형 모임 공간입니다. 대형 강연,
                  컨퍼런스 등을 개최할 수 있습니다.
                </p>
                <List bulleted className="gray small mt-0">
                  <List.Item>
                    <strong>수용인원</strong>
                    {' 100명 (약 80평) / 수용인원을 초과하는 대관은 메일로 문의해주세요.'}
                  </List.Item>
                  <List.Item>
                    <strong>용도</strong>

                    {' 이벤트, 강연, 세미나, 워크샵 등'}
                  </List.Item>
                  <List.Item>
                    <strong>주요시설</strong>
                    {
                      ' 책상(*바퀴) 14개, 의자 100개, 영상/음향 시스템(정면/측면 스크린 각 1개, 유/무선 마이크 각 2개)'
                    }
                  </List.Item>
                </List>
                <Button as={Link} to="/spaces/1" size="small" content="자세히 보기" />
              </Grid.Column>

              <Grid.Column>
                <Image src={space2} />
                <Header as="h3" content="세미나룸 '공감 Empathy'" className="mt-1 mb-q" />
                <p className="text mb-h">
                  소규모 강연 및 모임을 개최할 수 있는 세미나룸은 체인지메이커들이 공감을 경험하고
                  나눌 수 있는 공간입니다.
                </p>
                <List bulleted className="gray small mt-0">
                  <List.Item>
                    <strong>수용인원</strong>

                    {' 40명 (약 35평) / 수용인원을 초과하는 대관은 메일로 문의해주세요.'}
                  </List.Item>
                  <List.Item>
                    <strong>용도</strong>

                    {' 강연, 세미나, 워크샵, 미팅 등'}
                  </List.Item>
                  <List.Item>
                    <strong>주요시설</strong>
                    {
                      ' 책상 8개, 의자(*바퀴) 36개, 영상/음향 시스템 (정면 스크린 1개, 무선 마이크 2개)'
                    }
                  </List.Item>
                </List>
                <Button as={Link} to="/spaces/2" size="small" content="자세히 보기" />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Divider className="mv-5 mv-3-xxs mh-20p" />
          <Calendar />
        </Container>
      </div>
      <ReservationGuide />
      <Footer />
      <MetaData
        title="체인지메이커스 공간"
        description="성수동 헤이그라운드(HEYGROUND) 지하 1층에 위치한 모임 공간 체인지메이커스(CHANGEMAKERS)는 체인지메이킹 문화 확산 운동의 커뮤니티 허브입니다"
      />
    </>
  );
}
