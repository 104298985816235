import moment from 'moment';
import 'moment/locale/ko';

moment.locale('ko');

function Time({ unix, format }) {
  return moment(unix * 1000).format(format);
}

export default Time;
