import React from 'react';
import { Header, List, Container } from 'semantic-ui-react';

export default function LibraryPrivacy() {
  return (
    <Container className="mv-2">
      <Header as="h2">Privacy Policy</Header>
      <p>Updated on October 21, 2020</p>
      <p>
        This policy describes the information we process to support Changemaker Library offered by
        Ashoka Korea(“we”, or “us”).
      </p>
      <p>
        We may change this Privacy Policy from time to time. If we make changes, we will notify you
        by revising the date at the top of this policy and, in some cases, we may provide you with
        additional notice (such as adding a statement to our platform or providing you with a
        notification).
      </p>
      <Header>Collection of information</Header>
      We collect information you provide directly to us. For example, you share information directly
      with us when you create an account, fill out a form, submit or post content through our
      platform, request customer support, or otherwise communicate with us. The types of personal
      information we may collect include your name, email address, password, your content, including profile image,
      posts, comments, responses, and any other information you choose to provide.
      <Header>Use of Information</Header>
      We use the information we collect from all our platform for the following purposes to:
      <List bulleted>
        <List.Item>Maintain and improve the Platform</List.Item>
        <List.Item>
          Send administrative information to you, for example, information regarding the Platform
          and changes to our terms, conditions, and policies
        </List.Item>
        <List.Item>Analyze how you interact with our Platform</List.Item>
        <List.Item>Respond to your inquiries, comments, feedback, or questions</List.Item>
        <List.Item>
          Detect, investigate, and prevent security incidents and other malicious, deceptive,
          fraudulent, or illegal activity and protect the rights and property of Ashoka Korea and
          others;
        </List.Item>
        <List.Item>Debug to identify and repair errors in our Platform</List.Item>
        <List.Item>Comply with legal obligations and legal process</List.Item>
      </List>
      <Header>Sharing of information</Header>
      We share personal information in the following circumstances or as otherwise described in this
      policy:
      <List bulleted>
        <List.Item>
          We share personal information with other users of the Platform. For example, if you use
          our Platform to publish content, post comments or certain information about you will be
          visible to others, such as your name, information about your activities on our Platform
          (e.g., posts, comments and responses).
        </List.Item>
        <List.Item>
          We may disclose personal information if we believe that disclosure is in accordance with,
          or required by, any applicable law or legal process, including lawful requests by public
          authorities to meet national security or law enforcement requirements.
        </List.Item>
        <List.Item>
          We may share personal information if we believe that your actions are inconsistent with
          our user agreements or policies, if we believe that you have violated the law, or if we
          believe it is necessary to protect the rights, property, and safety of Ashoka Korea, our
          users, the public, or others.
        </List.Item>
        <List.Item>We share personal information with your consent or at your direction.</List.Item>
        <List.Item>
          We also share aggregated or de-identified information that cannot reasonably be used to
          identify you.
        </List.Item>
        <List.Item>
          We use Amazon Web Services, our service provider that stores and processes personal
          information for us. For more on Amazon Web Services’ privacy practices, see their Privacy
          Policy https://aws.amazon.com/privacy/
        </List.Item>
      </List>
      <Header>Update your Information</Header>
      If you need to change or correct your Personal Data, or wish to have it deleted from our
      systems, you may contact us at dev@ashokakorea.com. We will address your request as required
      by applicable law.
      <Header>Additional disclosures for individuals in Europe</Header>
      The sections below apply to you if you use our Services while in Switzerland, the United
      Kingdom or the European Economic Area (collectively, “Europe”).
      <List bulleted>
        <List.Item>
          Legal Basis for Processing
          <br />
          When we process your personal data we will do so in the following lawful base:
          <List.List>
            <List.Item>
              As necessary to perform our responsibilities under our contract with you (like
              providing the services you have requested).
            </List.Item>
            <List.Item>
              When we have a legitimate interest in processing your personal data, including to
              communicate with you about changes to our Platform, to help secure and improve our
              Platform (including to prevent fraud) and to analyze use of our Platform.
            </List.Item>
            <List.Item>As necessary to comply with our legal obligations</List.Item>
            <List.Item>When we have your consent to do so.</List.Item>
          </List.List>
        </List.Item>
        <List.Item>
          Data Retention
          <List.List>
            <List.Item>
              We retain personal data for no longer than is necessary for the purposes for which it
              is processed, unless applicable law requires storage for a longer period of time.
            </List.Item>
          </List.List>
        </List.Item>
        <List.Item>
          Data Subject Requests
          <br />
          Subject to certain limits and conditions provided under law, you have the following
          rights:
          <List.List>
            <List.Item>
              You have the right to access personal data we hold about you and to ask that your
              personal data be corrected, erased, or transferred.
            </List.Item>
            <List.Item>
              You also have the right to object to certain processing, or request that we restrict
              processing in certain circumstances (like to retain but not further process pending
              resolution of a claim).
            </List.Item>
            <List.Item>
              When we ask for your consent, you may withdraw that consent at any time.
            </List.Item>
            <List.Item>
              You have the right to file a complaint regarding our data protection practices with a
              supervisory authority.
            </List.Item>
          </List.List>
        </List.Item>
      </List>
      <Header>Children under age of 13</Header>
      Our Services are not intended for children under 13 years of age and we do not knowingly
      collect personal information from children under the age of 13. If you are a parent or
      guardian of a child under the age of 13 and believe he or she has disclosed personally
      identifiable information to us, please contact us at dev@ashokakorea.com. A parent or guardian
      of a child under age 13 may review and request deletion of the child’s personal information.
      <Header>Contact US</Header>
      If you have any questions about this Privacy Policy, please contact us at: dev@ashokakorea.com
    </Container>
  );
}
