import React from 'react';
import { Container, Divider, Grid, Header, Icon, List } from 'semantic-ui-react';
import Slider from 'react-slick';
import ReservationGuide from './ReservationGuide';
import { Footer, MetaData, Section } from '../commons';
import { empathy1, empathy2, empathy3 } from '../../images/spaces';

const Space2 = () => {
  const spaceImages = [empathy1, empathy2, empathy3];
  return (
    <>
      <Slider arrows dots autoplay>
        {spaceImages.map(image => {
          return (
            <div key={image}>
              <Section url={image} />
            </div>
          );
        })}
      </Slider>

      <div className="bg-whitesmoke pv-5">
        <Container text textAlign="center">
          <Header as="h2" content="세미나룸 '공감 Empathy'" />
          <p className="ta-l">
            소규모 강연 및 모임을 개최할 수 있는 세미나룸은 체인지메이커들이 공감을 경험하고 나눌 수
            있는 공간입니다.
          </p>
          <List bulleted className="ta-l gray small">
            <List.Item>
              <strong>수용인원</strong>

              {' 40명 (약 35평) / 수용인원을 초과하는 대관은 메일로 문의해주세요.'}
            </List.Item>
            <List.Item>
              <strong>용도</strong>

              {' 강연, 세미나, 워크샵, 미팅 등'}
            </List.Item>
            <List.Item>
              <strong>주요시설</strong>
              {' 책상 8개, 의자(*바퀴) 36개, 영상/음향 시스템 (정면 스크린 1개, 무선 마이크 2개)'}
            </List.Item>
          </List>
          <Divider className="mv-2 mh-20p" />
          <Grid>
            <Grid.Row>
              <Grid.Column only="tablet computer" tablet={2} computer={2} largeScreen={2} />
              <Grid.Column mobile={4} tablet={3} computer={3} largeScreen={3}>
                <Icon name="desktop" className="darkgray fs-3" />
                <p className="small gray mt-h">프로젝터/스크린</p>
              </Grid.Column>
              <Grid.Column mobile={4} tablet={3} computer={3} largeScreen={3}>
                <Icon name="microphone" className="darkgray fs-3" />
                <p className="small gray mt-h">마이크(유/무선)</p>
              </Grid.Column>
              <Grid.Column mobile={4} tablet={3} computer={3} largeScreen={3}>
                <Icon name="headphones" className="darkgray fs-3" />
                <p className="small gray mt-h">헤드셋</p>
              </Grid.Column>
              <Grid.Column mobile={4} tablet={3} computer={3} largeScreen={3}>
                <Icon name="server" className="darkgray fs-3" />
                <p className="small gray mt-h">음향 시스템</p>
              </Grid.Column>
              <Grid.Column only="tablet computer" tablet={2} computer={2} largeScreen={2} />
            </Grid.Row>
          </Grid>
        </Container>
      </div>

      <ReservationGuide spaceId={2} />
      <Footer />
      <MetaData
        title="세미나룸 '공감 Empathy'"
        description="소규모 강연 및 모임을 개최할 수 있는 세미나룸은 체인지메이커들이
            공감을 경험하고 나눌 수 있는 공간입니다."
        image={empathy1}
      />
    </>
  );
};

export default Space2;
