import React from 'react';
import { Container, Divider, Grid, Header, Image, Message } from 'semantic-ui-react';
import { Footer, MetaData, Section } from './commons';
import { coverPrograms } from '../images/cover';
import {
  ashoka,
  awesomeProgram,
  awesomeschool,
  changemakers,
  futureclassnet,
  hanyang,
  impactProgram,
  jump,
  gonggaminNarrow,
  mta,
  rootimpact,
  gonggaminProject,
  opencollege,
} from '../images/logo';

export default function Programs() {
  return (
    <>
      <Section url={coverPrograms} />
      <div className="bg-white pv-5">
        <Container text textAlign="center">
          <Header as="h2" content="Everyone A Changemaker™" />
          <p className="ta-l">
            체인지메이커스는 자라나는 세대부터 혁신가들까지 모두가 체인지메이커의 자질인 공감 능력,
            팀워크, 협력적 리더십, 체인지메이킹 역량을 배우고 적용하고 실행할 수 있도록 다양한
            프로그램으로 채워가고 있습니다
          </p>
        </Container>
      </div>

      <div className="bg-whitesmoke pv-5 bt-1">
        <Container text textAlign="center">
          <Header as="h2" content="시그니처 프로그램" />
          <p className="ta-l">
            국내와 해외에서 활동하고 있는 아쇼카 펠로우*들의 혁신적이고 새로운 시도를 위한
            인큐베이팅의 장을 제공합니다. 변화의 선구자들인 펠로우와 로컬 커뮤니티가 서로 협업할 수
            있는 기회를 제공함으로써 체인지메이킹 무브먼트 확산의 구심점 역할을 하게 됩니다.
          </p>
          <p className="small gray ta-l">
            *아쇼카 펠로우: 아쇼카 펠로우는 사회문제를 해결하기 위해 사회 시스템 차원의 변화를
            이끌어내는 뛰어난 소셜 앙터프리너(Leading Social Entrepreneur) 입니다. 아쇼카 펠로우들은
            궁극적으로 사회의 패턴과 시스템을 변화시켜 사회 문제를 근본적으로 해결하는 것을 목표로
            합니다.
            <br />
            <a
              href="https://www.ashoka.org/en-KR"
              target="_blank"
              rel="noopener noreferrer"
              className="fw-b"
            >
              아쇼카 펠로우 자세히보기
            </a>
          </p>
          <Divider className="mv-2 mh-20p" />
          <Grid className="ta-l">
            <Grid.Row>
              <Grid.Column mobile={16} tablet={4} computer={4} largeScreen={4}>
                <Image centered src={gonggaminNarrow} />
                <Image centered src={gonggaminProject} className="mt-2" />
              </Grid.Column>
              <Grid.Column mobile={16} tablet={12} computer={12} largeScreen={12}>
                <Header as="h3" content="정혜신 아쇼카 펠로우와 ‘공감인’" className="mb-h" />
                <p>
                  공감인은 모든 마음이 존중받는 일상을 위해 치유를 경험한 시민이 다른 시민을
                  치유하는 치유릴레이를 이끄는 치유활동가집단입니다. 공감의 경험을 통해 마음이
                  건강한 사회를 만들기 위해 노력하고 있습니다. 공감인의 모든 프로그램은 사람들의
                  마음에 온기를 전하고 함께 소통하며 자연스럽게 치유가 일어날 수 있는 시간으로
                  채워집니다.
                </p>
                <p className="mb-h">
                  정신과 의사이자 아쇼카 한국 펠로우이신 정혜신 박사가 서울시와 기획한 공감인의 대표
                  프로그램인 ‘마음:온’과 ‘속마음프로젝트’는 ‘모든 인간은 개별적 존재이며 동시에 모두
                  치유적 존재’라는 대전제에서 출발합니다. 전문가가 일반인을 치유하는 권위적이고
                  수직적인 치유가 아니라, 치유를 경험한 시민(wounded healer)이 또 다른 시민을
                  치유하는 ‘깊고 소박한 치유 릴레이’를 통해 우리 사회에 치유적 공기를 퍼뜨리는
                  프로그램입니다. 고단하고 각박한 사회 속에서 상처받으며 살아가고 있는 나의 삶과
                  마음에 집중하며 진짜인 나를 만나는 시간을 선사합니다.
                </p>
                <p className="small darkgray">
                  <a
                    href="https://www.gonggamin.org"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="fw-b darkgray"
                  >
                    Homepage
                  </a>
                  {' ・ '}
                  <a
                    href="https://business.facebook.com/gonggamins"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="fw-b darkgray"
                  >
                    Facebook
                  </a>
                </p>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column mobile={16} tablet={4} computer={4} largeScreen={4}>
                <Image centered src={futureclassnet} />
              </Grid.Column>
              <Grid.Column mobile={16} tablet={12} computer={12} largeScreen={12}>
                <Header
                  as="h3"
                  content="정찬필 아쇼카 펠로우와 ‘미래교실 네트워크’"
                  className="mb-h"
                />
                <p>
                  “모두가 교육의 위기를 말할 때 우리는 희망을 만들어 왔습니다” 정찬필 펠로우는
                  자라나는 세대들이 협력적 문제 해결 능력과 같은 21세기 역량을 기를 수 있도록,
                  교실에서 바로 실행가능한 교육 방법론들을 공교육 교사들에게 확산하여 미래 교육의
                  패러다임 전환을 가속화하고 있습니다.
                </p>
                <p>
                  정찬필 펠로우와 ‘미래교실네트워크’는 현재 한국의 교육 위기를 세계적 시야에서
                  재조명하고 이를 돌파할 혁신적이고 즉각적인 실현가능한 대안을 제시하고 있습니다.
                  이를 위해 현직 교사들을 중심으로 하는 민간 교육혁신 네트워크를 구성하고, 교육
                  혁신에 공감하는 다양한 분야의 전문가들이 모여 소통과 협력을 바탕으로 한 집단
                  지성으로 교육 혁신의 그치지 않는 동력을 만들어내고자 합니다.
                </p>
                <p className="mb-h">
                  <strong>주요 프로그램 |</strong>
                  {
                    ' 교육 혁신을 위한 교사/학부모/학생 워크샵. 21세기 학교 ‘거꾸로 캠퍼스’의 다양한 활동'
                  }
                </p>
                <p className="small darkgray">
                  <a
                    href="https://www.futureclassnet.org"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="fw-b darkgray"
                  >
                    Homepage
                  </a>
                  {' ・ '}
                  <a
                    href="https://www.facebook.com/futureclassnet"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="fw-b darkgray"
                  >
                    Facebook
                  </a>
                </p>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column mobile={16} tablet={4} computer={4} largeScreen={4}>
                <Image centered src={mta} />
              </Grid.Column>
              <Grid.Column mobile={16} tablet={12} computer={12} largeScreen={12}>
                <Header
                  as="h3"
                  content="Jose Mari 아쇼카 스페인 펠로우와 ‘MTA 체인지메이커랩’"
                  className="mb-h"
                />
                <p>
                  MTA에는 학생이 없습니다. 교수도 없고, 교재도, 시험도, 교실도 없습니다. MTA에는
                  실제 사업을 운영하는 팀프로뉴어(Teampreneur)가 있으며, 이를 지원해주는 팀코치만
                  있습니다. 체인지메이커랩에 합류하게 되면 팀을 이루어 실제 비즈니스를 수행하게
                  되고, 팀으로 다양한 프로젝트를 하게 됩니다. 스스로 돈을 마련하고, 사업을 진행하고,
                  고객을 직접 만나게 됩니다. 세상을 새롭게 변화시킬 비즈니스 아이디어를 개발해서,
                  생산, 마케팅, 판매, 세금계산서 발행까지 모든 과정을 스스로 하게 됩니다.
                </p>
                <p className="mb-h">
                  1993년 핀란드 유바스칼라 대학에서 처음 시작된 팀아카데미의 교육 방법은 스페인
                  몬드라곤 대학을 만나 스페인, 핀란드, 미국, 중국, 인도, 한국 등 전세계를 누비는
                  몬드라곤팀아카데미(MTA)로 다시 태어났습니다. 이제 MTA는 아쇼카한국와 함께 사회혁신
                  비즈니스를 창출하는 체인지메이커를 양성하는 체인지메이커랩을 한국에 만들었습니다.
                  MTA의 체인지메이커들이 만들어갈 새로운 대한민국을 기대합니다.
                </p>
                <p className="small darkgray">
                  <a
                    href="https://www.facebook.com/mtakorea2016"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="fw-b darkgray"
                  >
                    Facebook
                  </a>
                  {' ・ '}
                  <a
                    href="https://www.instagram.com/mta_korea"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="fw-b darkgray"
                  >
                    Instagram
                  </a>
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>

      <div className="bg-white pv-5 bt-1">
        <Container text textAlign="center">
          <Header as="h2" content="Team of Teams 프로그램" />
          <p className="ta-l">
            Team of Teams는 기존의 경계선들을 넘나들면서 복잡한 문제들을 해결하고 새로운 기회들을
            포착하기 위한 협력의 새로운 개념으로, Team of Teams 협력 프로그램은 체인지메이킹 운동에
            동참하고 있는 커뮤니티 내의 파트너들과 협력을 통해 진행됩니다. 공감능력, 협력적
            리더십,팀워크, 체인지메이킹의 문화 확산을 위한 다채로운 주제로 진행됩니다.
          </p>
          <p className="small gray ta-l">
            {
              '※ Team of Teams(ToT) 파트너로서 한국 사회의 긍정적 변화와 임팩트 확산을 위한 활동을 전개하는 다양한 기관/프로그램과의 협력을 기다리고 있습니다. ToT 파트너로 선정되면 체인지메이커스 공간 사용, 모임 홍보 등의 혜택이 주어집니다. ToT 파트너십을 위한 문의는 '
            }
            <a href="mailto:info@changemakers.xyz" data-rel="external" className="fw-b">
              info@changemakers.xyz
            </a>
            {' 이메일로 보내주세요.'}
          </p>
          <Divider className="mv-2 mh-20p" />
          <Grid className="ta-l">
            <Grid.Row>
              <Grid.Column mobile={16} tablet={4} computer={4} largeScreen={4}>
                <Image centered src={rootimpact} />
                <Image centered src={impactProgram} />
              </Grid.Column>
              <Grid.Column mobile={16} tablet={12} computer={12} largeScreen={12}>
                <Header as="h3" content="루트임팩트" className="mb-h" />
                <p>
                  루트임팩트는 사회 곳곳의 체인지메이커를 발굴하고, 이들이 보다 효과적으로,
                  지속가능한 형태로 문제를 해결할 수 있도록 유/무형의 인프라를 Work, Life, Learn
                  측면에서 만들고 있습니다.
                </p>
                <p>
                  임팩트 베이스캠프는 더 나은 세상을 꿈꾸는 청년들을 위한 약 8주 간의 청년
                  체인지메이커 교육 프로그램으로, JP모간이 공식 후원하고 루트임팩트와 한양대학교
                  사회혁신센터가 공동 주관하고 있습니다.
                </p>
                <p>
                  임팩트 커리어 Y는 2주간의 집중적인 직무 교육 후, 체인지메이커 조직의 신입/인턴으로
                  입사하여 직접 커리어를 경험하는 공동채용 프로그램으로 Google.org의 후원으로
                  진행됩니다.
                </p>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column mobile={16} tablet={4} computer={4} largeScreen={4}>
                <Image centered src={jump} />
              </Grid.Column>
              <Grid.Column mobile={16} tablet={12} computer={12} largeScreen={12}>
                <Header as="h3" content="사단법인 점프" className="mb-h" />
                <p>
                  사단법인 점프는 다양한 배경을 가진 청소년의 공정한 교육기회를 확대하고, 미래
                  청년인재를 양성하여 나눔과 다양성의 가치를 실현하는 비영리 사단법인입니다. 점프는
                  배움과 나눔을 통해 청소년-장학샘-사회인 멘토 간에 선순환 고리를 형성하며, 다채로운
                  만남과 경험, 협업을 바탕으로 장학샘의 리더십 및 내적 성장을 돕는 사회인 멘토링을
                  운영합니다.
                </p>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column mobile={16} tablet={4} computer={4} largeScreen={4}>
                <Image centered src={hanyang} />
              </Grid.Column>
              <Grid.Column mobile={16} tablet={12} computer={12} largeScreen={12}>
                <Header as="h3" content="한양대학교 사회혁신센터" className="mb-h" />
                <p>
                  한양대학교 사회혁신센터는 봉사를 통해 지역사회에 바람직한 가치를 더하는
                  사회공헌활동, 각자의 전공을 살려 사회문제의 근본적이고 지속가능한 해결에
                  이바지하는 사회혁신활동을 통해 대학구성원들이 체인지메이커로 성장할 수 있도록 돕고
                  있습니다. 유엔 지속가능개발목표(SDGs)와 연관된 다양한 활동을 지원하고 있으며,
                  변화를 주도하는 외부기관/프로그램들과 적극 협력하여 체인지메이킹 무브먼트가 확산될
                  수 있도록 노력하고 있습니다.
                </p>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column mobile={16} tablet={4} computer={4} largeScreen={4}>
                <Image centered src={awesomeschool} />
                <Image centered src={awesomeProgram} />
              </Grid.Column>
              <Grid.Column mobile={16} tablet={12} computer={12} largeScreen={12}>
                <Header as="h3" content="어썸스쿨" className="mb-h" />
                <p>
                  어썸스쿨은 누구나 자신이 가진 재능과 가능성을 발견하고, 그것을 바탕으로 세상에
                  가치를 남길 수 있는 세상을 꿈꿉니다. 2013년부터 현재까지 6년동안 150명의
                  청년강사들을 양성하고 전국 500개 가량의 중,고등학교에 히어로스쿨과 하이챌린스쿨
                  프로그램을 운영하며, 학교 안의 또다른 학교를 세우고 청년과 청소년들의 기업가정신,
                  체인지메이킹 역량을 기르는데 기여하고 있습니다.
                </p>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column mobile={16} tablet={4} computer={4} largeScreen={4}>
                <Image centered src={opencollege} />
              </Grid.Column>
              <Grid.Column mobile={16} tablet={12} computer={12} largeScreen={12}>
                <Header as="h3" content="오픈컬리지" className="mb-h" />
                <p>
                  오픈컬리지는 다양한 사람들이 자유롭게 지식과 경험을 공유하며 누구나 무엇이든 배울
                  수 있는 환경을 만들어가는 커뮤니티입니다. 가치공유와 PBL(Project Based Learning)을
                  통해 사람들이 다양한 프로젝트와 배움을 만들어가고 있으며, 서로의 차이를 인정하는
                  것을 넘어 그 차이를 통해 함께 성장하는 사람 중심의 배움을 지향하고 있습니다.
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>

      <div className="bg-whitesmoke pv-5 bt-1">
        <Container text textAlign="center">
          <Header as="h2" content="아쇼카 기획 프로그램" />
          <p className="ta-l">
            체인지메이킹 무브먼트 간의 협업을 독려하고 시너지를 창출하기 위한 커뮤니티 빌딩
            프로그램으로 ‘아쇼카 한국’이 기획합니다. 세대 구분 없이 모든 체인지메이커들이 참여할 수
            있도록 하는 세미나, 워크샵, 컨퍼런스 등이 제공됩니다.
          </p>
          <Grid>
            <Grid.Row>
              <Grid.Column mobile={2} tablet={5} computer={5} largeScreen={5} />
              <Grid.Column mobile={6} tablet={3} computer={3} largeScreen={3}>
                <Image
                  as="a"
                  href="http://ashokakorea.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                  src={ashoka}
                  size="small"
                  verticalAlign="middle"
                />
              </Grid.Column>
              <Grid.Column mobile={6} tablet={3} computer={3} largeScreen={3}>
                <Image src={changemakers} size="small" verticalAlign="middle" />
              </Grid.Column>
              <Grid.Column mobile={2} tablet={5} computer={5} largeScreen={5} />
            </Grid.Row>
          </Grid>
        </Container>
      </div>

      <div className="bg-white pv-5 bt-1">
        <Container text textAlign="center">
          <Header as="h2" content="일반 대관" />
          <p className="ta-l">
            체인지메이킹 무브먼트가 확산 될 수 있도록 커뮤니티에 공간을 오픈 대관 합니다. 이를 통해
            사회 전반에 체인지메이킹 문화가 확산되고, 더 많은 사람들이 무브먼트에 동참할 수 있게
            됩니다.
          </p>
          <Message
            className="ta-l"
            info
            content="※ 체인지메이커스는 체인지메이킹 무브먼트에 동참하는 다양한 기관/프로그램들과의 협업 및 임팩트 확산을 주 목적으로 운영되고 있으며, 오픈 대관은 헤이그라운드 멤버사(주 2회)와 일반 대관(주 2회)으로 횟수를 제한하고 있습니다."
          />
        </Container>
      </div>
      <Footer />
      <MetaData
        title="체인지메이커스 프로그램"
        description="체인지메이커스는 자라나는 세대부터 혁신가들까지 모두가 체인지메이커의 자질인 공감 능력, 팀워크, 협력적 리더십, 체인지메이킹 역량을 배우고 적용하고 실행할 수 있도록 다양한 프로그램으로 채워가고 있습니다"
      />
    </>
  );
}
