const MODE = 'PRODUCTION';

let Globals = {
  BASE_URL: 'http://localhost:3000',
};

switch (MODE) {
  case 'PRODUCTION':
    Globals = {
      BASE_URL: 'https://api.changemakers.xyz',
    };
    break;
  default:
    break;
}

export default Globals;
