import React from 'react';
import { Header, List, Container } from 'semantic-ui-react';

export default function LibraryTerms() {
  return (
    <Container className="mv-2">
      <Header as="h2">Terms of Use</Header>
      <p>Welcome to Changemaker Library!</p>
      <p>
        These Terms of Use govern your use of Ashoka Korea's services (“Changemaker Library”) and
        provide information about the Changemaker Library Platform, outlined below. Please read these
        Terms of Use carefully because they govern your use of our Services.
      </p>
      <Header>Registration and Your Information</Header>
      You have to register an account to access and use the Changemaker Library Platform. You may not
      register more than one account or transfer your account to someone else. You are responsible
      for maintaining the confidentiality and security of your account credentials and may not
      disclose your credentials to any third party. You are responsible and liable for activities
      conducted through your account and must immediately notify Ashoka Korea if you suspect that
      your credentials have been lost, stolen, or your account is otherwise compromised.
      <Header>Privacy Policy</Header>
      Please refer to{' '}
      <a
        href="https://www.changemakers.xyz/changemaker_library_privacy/"
        data-rel="external"
        className="fw-b"
      >
        our Privacy Policy
      </a>{' '}
      for information on how we collect, use and disclose information from our users. You
      acknowledge and agree that your use of the Services is subject to our Privacy Policy.{' '}
      <Header>Your Conduct and Content</Header>
      When people stand behind their opinions and actions, our platform is safer and more
      accountable.
      <List bulleted>
        <List.Item>
          For this reason, you must:
          <List.List>
            <List.Item>Use the same name that you use in everyday life.</List.Item>
            <List.Item>
              Not share your password, give access to your Changemaker Library account to others, or
              transfer your account to anyone else (without our permission).
            </List.Item>
          </List.List>
        </List.Item>
        <List.Item>
          You may not use our Platform to do or share anything
          <List.List>
            <List.Item>
              That is unlawful, objectionable, misleading, discriminatory or fraudulent.
            </List.Item>
            <List.Item>
              That infringes or violates someone else's rights, including their intellectual
              property rights.
            </List.Item>
          </List.List>
        </List.Item>
        <List.Item>
          You may not upload viruses or malicious code or do anything that could disable,
          overburden, or impair the proper working or appearance of our Platform.
        </List.Item>
        <List.Item>
          You may not access or collect data from our Platform using automated means (without our
          prior permission) or attempt to access data you do not have permission to access.
        </List.Item>
      </List>
      <Header>Content Removal and Disabling or Terminating Your Account</Header>
      We can remove any content or information you share on the Platform if we believe that it
      violates these Terms of Use, our policies, or we are required to do so by law. We can refuse
      to provide or stop providing all or part of the Platform to you (including terminating or
      disabling your account) immediately to protect our community or services, or if you create
      risk or legal exposure for us, violate these Terms of Use or our policies, if you repeatedly
      infringe other people's intellectual property rights, or where we are permitted or required to
      do so by law. And we can take action to disable or terminate your account.
      <Header>Links to Other Websites</Header>
      The Platform may contain links to other websites not operated or controlled by us (“Third
      Party Sites”), including social media websites and services. The information that you share
      with Third Party Sites will be governed by the specific privacy policies and terms of service
      of such Third Party Sites and not by this Privacy Policy. By providing these links we do not
      imply that we endorse or have reviewed these sites. Please contact those sites directly for
      information on their privacy practices and policies.
      <Header>Additional provisions</Header>
      <Header as="h4">Updating These Terms</Header>
      <p>
        We may modify this Agreement to reflect changes to our Service or for legal, regulatory, or
        security reasons. We will provide reasonable notice of any material modifications to this
        Agreement. Modifications to this Agreement will only apply going forward. If you do not
        agree to the modified terms, you should remove any Content you have uploaded and discontinue
        your use of the Service.
      </p>
      <Header as="h4">Limits on liability</Header>
      <p>
        We work hard to provide the best Platform we can and to specify clear guidelines for
        everyone who uses them. Our Platform, however, are provided "as is," and we make no
        guarantees that they always will be safe, secure, or error-free, or that they will function
        without disruptions, delays, or imperfections. To the extent permitted by law, we also
        disclaim all warranties, whether express or implied, including the implied warranties of
        merchantability, fitness for a particular purpose, title, and non-infringement. We do not
        control or direct what people and others do or say, and we are not responsible for their
        actions or conduct (whether online or offline) or any content they share (including
        offensive, inappropriate, obscene, unlawful, and other objectionable content).
        <br />
        Notwithstanding the foregoing, applicable law may not allow the limitation or exclusion of
        liability set forth above. In such case, we shall bear liability to the extent required by
        such law.
      </p>
      <Header as="h4">Governing Law</Header>
      <p>
        These Terms and any action related thereto will be governed by the laws of the Republic of
        Korea. Conflicts that arise from the Service or conflicts between Users and Ashoka Korea
        related to the Service will be governed primarily under the exclusive jurisdiction of the
        district Court of Seoul.
      </p>
      <p>Effective as of November 17, 2020</p>
    </Container>
  );
}
