import gql from 'graphql-tag';
import RESERVATION_FRAGMENT from './RESERVATION_FRAGMENT';

const ReservationQuery = gql`
  query Reservation($id: ID!) {
    reservation(id: $id) {
      ...ReservationFragment
    }
  }
  ${RESERVATION_FRAGMENT}
`;

export default ReservationQuery;
