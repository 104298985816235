import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Redirect, useHistory } from 'react-router-dom';
import gql from 'graphql-tag';
import { RESERVATION_FRAGMENT } from '../../graphql/reservations';
import { ReservationForm } from '../reservations';

export const CREATE_RESERVATION = gql`
  mutation createReservation(
    $space_ids: [Int]
    $start_datetime: Int
    $end_datetime: Int
    $category: String
    $name: String
    $institution: String
    $email: String
    $phone: String
    $title: String
    $description: String
    $number_of_participants: Int
    $purpose: String
    $is_public: Boolean
    $cost: Int
    $microphone: Boolean
    $projector: Boolean
    $sound_equipment: Boolean
    $equipment_request: String
    $catering: Boolean
    $banner: Boolean
    $promotion: Boolean
    $website: String
    $agreed_to_terms: Boolean
    $agenda_signed_id: String
  ) {
    createReservation(
      agenda_signed_id: $agenda_signed_id
      reservation: {
        space_ids: $space_ids
        start_datetime: $start_datetime
        end_datetime: $end_datetime
        category: $category
        name: $name
        institution: $institution
        email: $email
        phone: $phone
        title: $title
        description: $description
        number_of_participants: $number_of_participants
        purpose: $purpose
        is_public: $is_public
        cost: $cost
        microphone: $microphone
        projector: $projector
        sound_equipment: $sound_equipment
        equipment_request: $equipment_request
        catering: $catering
        banner: $banner
        promotion: $promotion
        website: $website
        agreed_to_terms: $agreed_to_terms
      }
    ) {
      ...ReservationFragment
    }
  }
  ${RESERVATION_FRAGMENT}
`;

export default function ReservationStep2() {
  const history = useHistory();
  const [createReservation, { loading }] = useMutation(CREATE_RESERVATION);

  if (!history.location?.agreed) return <Redirect to="/spaces" />;
  return <ReservationForm submit={createReservation} loading={loading} history={history} />;
}
