import React from 'react';
import { Container, Divider, Grid, Header, Icon, List } from 'semantic-ui-react';
import Slider from 'react-slick';
import ReservationGuide from './ReservationGuide';
import { Footer, MetaData, Section } from '../commons';
import { inspiration1, inspiration2, inspiration3 } from '../../images/spaces';

const Space1 = () => {
  const spaceImages = [inspiration1, inspiration2, inspiration3];
  return (
    <>
      <Slider arrows dots autoplay>
        {spaceImages.map(image => {
          return (
            <div key={image}>
              <Section url={image} />
            </div>
          );
        })}
      </Slider>

      <div className="bg-whitesmoke pv-5">
        <Container text textAlign="center">
          <Header as="h2" content="다목적홀 '영감 Inspiration'" />
          <p className="ta-l">
            체인지메이킹을 위한 영감을 얻을 수 있는 대규모 개방형 모임 공간입니다. 공감, 팀워크,
            협력적 리더십, 체인지메이킹 역량을 배우고 적용하고 실행할 수 있는 장으로 대형 강연,
            컨퍼런스 등을 개최할 수 있습니다.
          </p>
          <List bulleted className="ta-l gray small">
            <List.Item>
              <strong>수용인원</strong>
              {' 100명 (약 80평) / 수용인원을 초과하는 대관은 메일로 문의해주세요.'}
            </List.Item>
            <List.Item>
              <strong>용도</strong>
              {' 이벤트, 강연, 세미나, 워크샵 등'}
            </List.Item>
            <List.Item>
              <strong>주요시설</strong>
              {
                ' 책상(*바퀴) 14개, 의자 100개, 영상/음향 시스템(정면/측면 스크린 각 1개, 유/무선 마이크 각 2개)'
              }
            </List.Item>
          </List>

          <Divider className="mv-2 mh-20p" />
          <Grid>
            <Grid.Row>
              <Grid.Column only="tablet computer" tablet={2} computer={2} largeScreen={2} />
              <Grid.Column mobile={4} tablet={3} computer={3} largeScreen={3}>
                <Icon name="desktop" className="darkgray fs-3" />
                <p className="small gray mt-h">프로젝터/스크린</p>
              </Grid.Column>
              <Grid.Column mobile={4} tablet={3} computer={3} largeScreen={3}>
                <Icon name="microphone" className="darkgray fs-3" />
                <p className="small gray mt-h">마이크(유/무선)</p>
              </Grid.Column>
              <Grid.Column mobile={4} tablet={3} computer={3} largeScreen={3}>
                <Icon name="headphones" className="darkgray fs-3" />
                <p className="small gray mt-h">헤드셋</p>
              </Grid.Column>
              <Grid.Column mobile={4} tablet={3} computer={3} largeScreen={3}>
                <Icon name="server" className="darkgray fs-3" />
                <p className="small gray mt-h">음향 시스템</p>
              </Grid.Column>
              <Grid.Column only="tablet computer" tablet={2} computer={2} largeScreen={2} />
            </Grid.Row>
          </Grid>
        </Container>
      </div>

      <ReservationGuide spaceId={1} />
      <Footer />
      <MetaData
        title="다목적홀 '영감 Inspiration'"
        description="체인지메이킹을 위한 영감을 얻을 수 있는 대규모 개방형 모임
            공간입니다. 공감, 팀워크, 협력적 리더십, 체인지메이킹 역량을 배우고
            적용하고 실행할 수 있는 장으로 대형 강연, 컨퍼런스 등을 개최할 수
            있습니다."
        image={inspiration1}
      />
    </>
  );
};

export default Space1;
