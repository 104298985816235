import React, { useState } from 'react';
import BigCalendar from 'react-big-calendar';
import moment from 'moment';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Link } from 'react-router-dom';
import { Icon, Label, Loader, Popup, Table } from 'semantic-ui-react';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { TableRow } from '../commons';
import './calendar.css';

const GET_EVENTS = gql`
  query Events($date: Int) {
    events(date: $date) {
      start_datetime
      end_datetime
      space_id
      space {
        id
        name
      }
    }
    reservations(date: $date) {
      id
      start_datetime
      end_datetime
      spaces {
        id
        name
      }
      purpose_ko
      calendar_title
      category_ko
      category_color
      institution
      is_manageable
      space_color
    }
  }
`;

const localizer = BigCalendar.momentLocalizer(moment); // or globalizeLocalizer

function Calendar() {
  const [date, setDate] = useState(moment());
  const [agenda, setAgenda] = useState(false);

  const formats = {
    dateFormat: 'D',
    dayFormat: (d, _, loc) => loc.format(d, 'M/D (dd)', _),
    monthHeaderFormat: 'YYYY년 M월',
    agendaDateFormat: 'M/D(dd)',
    agendaTimeFormat: 'a h:mm',
    dayHeaderFormat: 'M월 D일 (dd)',
    agendaTimeRangeFormat: ({ start, end }, culture, local) =>
      `${local.format(start, 'HH:mm', culture)} ~ ${local.format(end, 'HH:mm', culture)}`,
    agendaHeaderFormat: ({ start }, culture, local) => local.format(start, 'YYYY년 M월', culture),
  };

  const { error, data } = useQuery(GET_EVENTS, {
    variables: { date: moment(date).unix() },
  });

  if (error) return null;

  if (!data || !data.reservations || !data.events) return <Loader active inline="centered" />;

  let calendarReservations = [];
  if (data.reservations.length > 0) {
    calendarReservations = data.reservations.map(reservation => {
      return {
        id: reservation.id,
        start_datetime: moment(reservation.start_datetime * 1000),
        end_datetime: moment(reservation.end_datetime * 1000),
        title: reservation.calendar_title,
        spaces: reservation.spaces,
        purpose_ko: reservation.purpose_ko,
        category_ko: reservation.category_ko,
        category_color: reservation.category_color,
        institution: reservation.institution,
        is_manageable: reservation.is_manageable,
        space_color: reservation.space_color,
      };
    });
  }

  const openspaceEvents = data.events
    .sort((a, b) => {
      return (
        a.space_id - b.space_id ||
        a.start_datetime - b.start_datetime ||
        a.end_datetime - b.end_datetime
      );
    })
    .reduce((prev, current) => {
      const last = prev[prev.length - 1] || [];
      if (
        last.space_id === current.space_id &&
        last.start_datetime <= current.start_datetime &&
        current.start_datetime <= last.end_datetime
      ) {
        if (last.end_datetime < current.end_datetime) {
          last.end_datetime = current.end_datetime;
        }
        return prev;
      }
      return prev.concat(current);
    }, [])
    .map(event => {
      return {
        start_datetime: moment(event.start_datetime * 1000),
        end_datetime: moment(event.end_datetime * 1000),
        title: '오픈스페이스',
        spaces: [event.space],
        category_ko: '오픈스페이스',
      };
    });

  return (
    <>
      <BigCalendar
        formats={formats}
        views={['month', 'agenda']}
        localizer={localizer}
        events={[...calendarReservations, ...openspaceEvents]}
        startAccessor="start_datetime"
        endAccessor="end_datetime"
        culture="ko"
        date={date.startOf('month').toDate()}
        length={date.daysInMonth() - 1}
        messages={{
          today: '오늘',
          previous: <Icon name="angle left" color="grey" />,
          next: <Icon name="angle right" color="grey" />,
          date: '날짜',
          time: '시간',
          event: '모임',
          month: '캘린더',
          agenda: '목록',
          noEventsInRange: '등록된 모임이 없습니다.',
        }}
        onNavigate={(d, view) => {
          if (view === 'agenda') {
            setDate(
              moment(d)
                .add(5, 'day')
                .startOf('month'),
            );
          } else {
            setDate(moment(d));
          }
        }}
        onView={view => {
          if (view === 'agenda') {
            setAgenda(true);
          } else {
            setAgenda(false);
          }
        }}
        popup
        titleAccessor={reservation => {
          const {
            id,
            title,
            start_datetime,
            end_datetime,
            spaces,
            institution,
            category_ko,
            category_color,
            purpose_ko,
            is_manageable,
          } = reservation;
          if (agenda) {
            return (
              <span>
                <Label circular color={reservation.space_color} empty />
                {` ${reservation.title}`}
              </span>
            );
          }
          const trigger = (
            <div>
              <Label circular color={reservation.space_color} empty />
              <strong>{` ${moment(reservation.start_datetime).format('HH:mm')} `}</strong>
              {reservation.title}
            </div>
          );
          return (
            <Popup
              position="bottom left"
              verticalOffset={8}
              size="mini"
              on="click"
              style={{ right: 'none' }}
              trigger={trigger}
            >
              <Popup.Content>
                <Table basic="very" unstackable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell colSpan="2" content={title} />
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <TableRow title="일시">
                      {`${moment(start_datetime).format('LL dddd a h:mm')} ~ ${moment(
                        end_datetime,
                      ).format('a h:mm')}`}
                    </TableRow>
                    <TableRow title="장소">
                      {spaces.map(space => {
                        return (
                          <Label
                            size="mini"
                            as={Link}
                            to={`/spaces/${space.id}`}
                            key={space.id}
                            content={space.name}
                          />
                        );
                      })}
                    </TableRow>
                    {purpose_ko && <TableRow title="목적">{purpose_ko}</TableRow>}
                    {institution && <TableRow title="기관">{institution}</TableRow>}
                    <TableRow title="유형">
                      <Label size="mini" content={category_ko} color={category_color} />
                    </TableRow>
                    {is_manageable && (
                      <Table.Row>
                        <Table.Cell className="text" verticalAlign="top" colSpan="2" width={16}>
                          <Link to={`/reservations/${id}`}>자세히 보기</Link>
                        </Table.Cell>
                      </Table.Row>
                    )}
                  </Table.Body>
                </Table>
              </Popup.Content>
            </Popup>
          );
        }}
      />
      <div className="mt-1 ta-l">
        <span className="pr-1 gray">
          <Label circular color="teal" empty />
          {' 다목적홀(영감)'}
        </span>
        <span className="pr-1 gray">
          <Label circular color="yellow" empty />
          {' 세미나룸(공감)'}
        </span>
        <span className="pr-1 gray">
          <Label circular color="pink" empty />
          {' 전체'}
        </span>
      </div>
    </>
  );
}

export default Calendar;
