import React from 'react';
// import { useApolloClient } from '@apollo/react-hooks';
import { Link, useHistory } from 'react-router-dom';
import { Container, Image, Menu, Responsive } from 'semantic-ui-react';
import { logo, logoMobile } from '../images/logo';
// import { StatusContext } from '../StatusContext';

export default function Navbar() {
  const history = useHistory();
  // const client = useApolloClient();
  const { pathname } = history.location;

  if (pathname.includes('changemaker_library')) return null;
  const menu = (
    <>
      <Menu.Item as={Link} to="/spaces" name="대관 신청" active={pathname === '/spaces'} />
      <Menu.Item as={Link} to="/programs" name="프로그램" active={pathname === '/programs'} />

      {/* <StatusContext.Consumer>
        {({ authenticated, logout }) => {
          if (authenticated)
            return (
              <Dropdown
                direction="left"
                item
                text="더보기"
                className={['/reservations'].includes(pathname) ? 'active' : ''}
              >
                <Dropdown.Menu>
                  <Dropdown.Item
                    as={Link}
                    to="/reservations"
                    className={pathname === '/reservations' ? 'active' : ''}
                  >
                    신청내역
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      localStorage.removeItem('token');
                      localStorage.removeItem('userId');
                      logout();
                      client.resetStore();
                      history.push(`/`);
                    }}
                  >
                    로그아웃
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            );
          return <Menu.Item as={Link} to="/login" name="로그인" active={pathname === '/login'} />;
        }}
      </StatusContext.Consumer> */}
    </>
  );
  return (
    <>
      {/* mobile */}
      <Responsive maxWidth={767}>
        <Menu className="mobile-menu" borderless fixed="top" widths={4}>
          <Container>
            <Menu.Item as={Link} to="/">
              <Image src={logoMobile} size="mini" />
            </Menu.Item>
            {menu}
          </Container>
        </Menu>
        <div style={{ height: '3.3rem' }} />
      </Responsive>

      {/* desktop */}
      <Responsive minWidth={768}>
        <Menu borderless fixed="top">
          <Container>
            <Menu.Item as={Link} to="/">
              <Image src={logo} size="small" />
            </Menu.Item>
            <Menu.Menu position="right">{menu}</Menu.Menu>
          </Container>
        </Menu>
        <div style={{ height: '4.1rem' }} />
      </Responsive>
    </>
  );
}
