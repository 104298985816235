import React from 'react';
import { Helmet } from 'react-helmet';

const MetaData = ({ title, description, image }) => {
  return (
    <Helmet defaultTitle={title}>
      <meta property="og:title" content={title} />
      <meta name="twitter:title" content={title} />
      <meta name="description" content={description} />
      <meta property="og:description" content={description} />
      <meta name="twitter:description" content={description} />
      <meta property="og:image" content={image} />
      <meta name="twitter:image" content={image} />
    </Helmet>
  );
};

export default MetaData;
