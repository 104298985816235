import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';

const ANNOUNCEMENT = 'markAsReadAnnouncement23';
export default function Announcement() {
  const [modalVisible, setModalVisible] = useState(true);
  const history = useHistory();
  const { pathname } = history.location;

  useEffect(() => {
    if (localStorage.getItem(ANNOUNCEMENT)) {
      setModalVisible(false);
    }
  }, [modalVisible]);

  if (pathname.includes('changemaker_library')) return null;

  return (
    <Modal onClose={() => setModalVisible(false)} open={modalVisible} size="tiny">
      <Modal.Header content="대관 운영 종료 안내" />
      <Modal.Content>
        {/* <Header as="h3" content="대관 운영 종료 안내" className="mb-h" /> */}
        <p>
          아쇼카 한국이 체인지메이킹 무브먼트를 위해 운영하던 체인지메이커스의 대관 운영을
          종료합니다. 그동안 체인지메이커스를 이용해주신 체인지메이커 여러분께 깊은 감사를 드리며,
          자세한 이후 계획은 공지를 통해 알리도록 하겠습니다.
        </p>
        <br />
        <p>새로운 공간의 탄생을 위해 많은 기대와 응원 부탁드립니다.</p>
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={e => {
            e.preventDefault();
            setModalVisible(false);
            localStorage.setItem(ANNOUNCEMENT, true);
          }}
          content="다시 보지 않기"
        />
        <Button
          onClick={e => {
            e.preventDefault();
            setModalVisible(false);
          }}
          primary
          content="확인"
        />
      </Modal.Actions>
    </Modal>
  );
}
